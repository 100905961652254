import React, { useEffect, useRef, useState } from 'react'
import styles from './AboutUs.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useInView } from 'framer-motion';
import Typewriter from 'typewriter-effect';
import styledEngine from '@mui/styled-engine';
import { width } from '@mui/system';

function AboutUS() {
    const pageTitle = 'Our Mission | About Us';
    const pageDescription = 'Helping brands in their growth story with all that they need!';

    const bannerRef = useRef(null);
    const whiteRef = useRef(null);
    const purpleRef = useRef(null);
    const pinkRef = useRef(null);
    const blackRef = useRef(null);


    const bannerInView = useInView(bannerRef, { once: false });
    const whiteInView = useInView(whiteRef, { once: false });
    const purpleInView = useInView(purpleRef, { once: false });
    const pinkInView = useInView(pinkRef, { once: false });
    const blackInView = useInView(blackRef, { once: false });

    const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })

    useEffect(() => {

        if (bannerInView) {
            document.body.style.backgroundColor = '#0D0D0D';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ positon: 'relative' });
            console.log("banner")

        }


        if (whiteInView) {
            document.body.style.backgroundColor = '#1C1C1C';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ position: 'fixed' });
            // console.log("white")
            // setShowHyperspace(false);
        }
        if (purpleInView) {
            document.body.style.backgroundColor = '#E6D4FD';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ position: 'fixed' });
        }

        if (pinkInView) {
            document.body.style.backgroundColor = '#FFE0DF';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ position: 'fixed' });
        }

        if (blackInView) {
            document.body.style.backgroundColor = 'transparent'
            document.body.style.backgroundImage = "url('/assets/images/black-bg.png')";
            // setNavbarStyle({ position: 'fixed' });
            return;
        }

    }, [whiteInView, bannerInView]);


    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Building Brands | About Us</title>
                <meta name="description" content="We love brands. Our goal is to help growing indian consumers with innovative products that they can relate to and make them part of their journey." />

                <meta property="og:title" content="Building Brands | About Us" />
                <meta property="og:description" content="We love brands. Our goal is to help growing indian consumers with innovative products that they can relate to and make them part of their journey." />
                <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

                <meta name="twitter:title" content="Building Brands | About Us" />
                <meta name="twitter:description" content="We love brands. Our goal is to help growing indian consumers with innovative products that they can relate to and make them part of their journey." />
                <meta property="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />
            </Helmet>

            <Navbar style={navbarstyle} />



            <div className={styles.container}>
                <img
                    src="/assets/images/planet3.png"
                    alt="planets"
                    className={styles.planet54}
                    style={{ position: 'absolute', left: '5%', top: '10%' }}
                />
                <img
                    src="/assets/images/about-planet.png"
                    alt="planets"
                    className={styles.planet55}
                />
                <img
                    src="/assets/images/planet3.png"
                    alt="planets"
                    className={styles.planet56}
                />
                <img
                    src="/assets/images/blue-planet1.png"
                    alt="planets"
                    className={styles.planet57}
                />
                <img
                    src="/assets/images/about-planet1.png"
                    alt="planets"
                    className={styles.planet58}
                />
                {/* <img
                    src="/assets/images/red-planet4.png"
                    alt="planets"
                    className={styles.planet59}
                /> */}
                <img
                    src="/assets/images/about-planet2.png"
                    alt="planets"
                    className={styles.planet60}
                />
                <img
                    src="/assets/images/red-planet2.png"
                    alt="planets"
                    className={styles.planet61}
                />
                <img
                    src="/assets/images/red-planet4.png"
                    alt="planets"
                    className={styles.planet62}
                />
                <img
                    src="/assets/images/blue-planet2.png"
                    alt="planets"
                    className={styles.planet63}
                />
                <img
                    src="/assets/images/about-planet3.png"
                    alt="planets"
                    className={styles.planet64}
                />
                {/* <img src='https://assets.gohyper.space/web-landing/images/Group+5927.png' alt='planet' className={styles.planet1} /> */}
                <div className={styles.contentContainer} ref={bannerRef}>
                    <h1 className={styles.mainHeadings}>
                        Our mission is to <span className={styles.highlight}>help brands grow</span> and <span className={styles.highlight}>unlock opportunities</span> with technology.
                    </h1>
                    <div style={{ marginTop: '70px' }}>
                        <Link to="/contact-us" className={styles.getStartedBtn}>Be a part of our story</Link>

                    </div>


                </div>
                <div ref={whiteRef} style={{ maxWidth: '100%' }}>


                    <div className={styles.littleBgContainer}>
                        <div className={styles.littleContentDiv}>
                            <div>
                                <h1 className={styles.mainHeadings} style={{ color: '#FFFFFF' }}>A little <span>background</span></h1>
                                <p>
                                    At hyperspace. We want to help you grow your brand effectively. Each brand has a story to tell,
                                    and we make sure that you can tell yours. Of course, there are
                                    a lot of aspects to building a brand and even more so a sustainable business.
                                </p>
                            </div>
                            <div className={styles.cardContainer}>
                                <div className={styles.card1}>
                                    <h4>
                                        {/* And to address this we built hyperspace. */}
                                        From GTM strategy & <span className={styles.highlight}>marketing </span> to
                                        <span className={styles.highlight}> logistics</span>, <span className={styles.highlight}>distribution</span>  & <span className={styles.highlight}>financing</span>,
                                        we got you covered!
                                    </h4>

                                </div>
                                <p className={styles.retailIsHere}>A Plug & Play infrastructure to help you SCALE.</p>

                            </div>


                        </div>
                        <div className={styles.convictionContainer}>
                            <h1 className={styles.mainHeadings} style={{ color: '#FFFFFF' }}>Our Conviction</h1>
                            <div className={styles.convictionContent}>
                                <p>The Indian eCommerce landscape is <span>transforming</span>. It's not just about the big corporations; it's
                                    about the <span>hyperlocal businesses</span>—contributing to the rise in per capita income.
                                </p>
                                <p>
                                    And at <span>hyperspace,</span>  we are not just witnesses but <span>architects of  this change.</span>
                                </p>
                                <p>
                                    We <span>believe</span> in <span>empowering local businesses</span> and providing them with the <span>technological edge
                                        to compete globally.</span>
                                </p>
                                <p>
                                    In a world where conscious buying is gaining momentum, <span>we stand as a catalyst for
                                        businesses that align with the values of forever-evolving consumers.</span>
                                </p>

                            </div>
                            <div style={{ background: '#181818', width: '1020px', maxWidth: '100%' }}>
                                <img src='../assets/images/rectangles11.png' alt='rectangles' style={{ width: '100%' }} />
                            </div>

                        </div>

                    </div>
                    <div className={styles.shadeBgContainer}>

                        {/* <div className={styles.shadeContent}>
                        <p className={styles.description}>
                            We are kind, honest and helpful. We have also been told that we are very creative.
                            Blame our cultural values.
                        </p>

                    </div> */}
                        <div className={styles.crewContainer}>
                            <h2 className={styles.mainHeadings}>Join our crew <br />and <span className={styles.highlight}>build better brands</span></h2>
                            <p className={styles.description}>
                                Our software cuts through heaps of data and presents effective analytics to brands enabling them to scale effectively, quickly & so much more!. We do all of this by fostering a culture that encourages accountability, empathy & transparency.
                            </p>

                        </div>
                        <div>
                            <Link to="/contact-us" className={styles.getStartedBtn}>What role will you play?</Link>

                        </div>
                        <div className={styles.shadeContainer}>
                            {/* <img src="../assets/images/shade.png" alt='shade' className={styles.shade} /> */}

                            <p className={styles.weText}>At hyperspace. </p>
                            <Typewriter
                                options={{
                                    loop: true,
                                    autoStart: true,
                                    strings: ['we respect each other', 'we always find a way', 'we find courage in honesty', 'we actually give a shit'],
                                }}
                            />

                            {/* <p className={styles.respectText}>respect each <br />other</p> */}

                        </div>
                        <div className={styles.questionsContainer}>
                            <div className={styles.questionsBox1}>
                                <div className={styles.questionsCard1}>
                                    <h4 className={styles.cardheadings}>
                                        <span className={styles.highlight}>Already</span><br />
                                        one of Our<br />
                                        Brand-partners?
                                    </h4>
                                    <p>Our support team is happy to
                                        help.
                                    </p>

                                    <div>
                                        <Link to="/contact-us" className={styles.getStartedBtn}>Get Started</Link>

                                    </div>

                                </div>
                                <div className={styles.questionsCard1} style={{ backgroundColor: '#333333' }}>
                                    <h4 className={styles.cardheadings}>
                                        <span className={styles.highlight}>Got</span> another<br />
                                        Question?<br />
                                        Shoot.
                                    </h4>
                                    <p>Feel free to ask us anything
                                        about brands.
                                    </p>

                                    <div>
                                        <Link to="/contact-us" className={styles.getStartedBtn}>Contact Us</Link>

                                    </div>

                                </div>

                            </div>
                            <div className={styles.questionsBox2}>
                                <h4 className={styles.cardheadings}>
                                    <span className={styles.highlight}>Interested</span> in Our solution or services?


                                </h4>
                                <p>Schedule a call back from our
                                    experts.
                                </p>

                                <div>
                                    <Link to="/contact-us" className={styles.getStartedBtn}>Contact Us</Link>

                                </div>


                            </div>
                        </div>
                        <Footer />

                    </div>
                </div>



                {/* <div className={styles.meetCrewContainer}>
                    <div className={styles.meetCrew}>
                        <div className={styles.headings}>
                            <h2>Meet the Crew!</h2>
                            <img
                                src="https://assets.gohyper.space/web-landing/images/Group+5796.png"
                                alt="planets"
                                className={styles.planet3}
                            />
                        </div>
                        <ul className={styles.teamMembersContainer}>
                            <li className={styles.teamMemberCard}>
                                <img src="../assets/images/Rakesh.png" alt='team-member' />
                                <h4>Rakesh Munnanooru</h4>
                                <p>CEO</p>
                            </li>
                            <li className={styles.teamMemberCard}>
                                <img src="../assets/images/RakeshKasa.png" alt='team-member' />
                                <h4>Rakesh Kasa</h4>
                                <p>CTO</p>
                            </li>
                        </ul>
                        <ul className={styles.teamMembersContainer}>
                            <li className={styles.teamMemberCard}>
                                <img src="../assets/images/ved.png" alt='team-member' />
                                <h4>Sampath Vedantam</h4>
                                <p className={styles.paragraph}>Growth</p>
                            </li>
                            <li className={styles.teamMemberCard}>
                                <img src="../assets/images/Anvesh.png" alt='team-member' />
                                <h4>Anvesh Munnanooru</h4>
                                <p className={styles.paragraph}>Product</p>
                            </li>
                            <li className={styles.teamMemberCard}>
                                <img src="../assets/images/vinay.png" alt='team-member' />
                                <h4>Vinay Reddy Yarabolu​</h4>
                                <p className={styles.paragraph}>Strategy</p>
                            </li>
                            <li className={styles.teamMemberCard}>
                                <img src="../assets/images/Ramu.png" alt='team-member' />
                                <h4>Rama Lingeshwara​</h4>
                                <p className={styles.paragraph}>Design</p>
                            </li>
                        </ul>
                    </div>
                </div> */}
                {/* <div className={styles.starShipContainer}>
                    <img
                        alt="planets"
                        src="https://assets.gohyper.space/web-landing/images/Group+5087.png"
                    />
                    <div className={styles.starShipContent}>
                        <div className={styles.starship}>
                            <h3>
                                Interested to join our Starship?
                            </h3>
                            <p>
                                We are looking for amazing pilots to join, always :)
                            </p>
                            <div className={styles.button}>
                                <div className={styles.button1}>
                                    <Link to="/waitlist" className={styles.btn}>Apply Now</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default AboutUS
