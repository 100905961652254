import React, { useRef, useEffect, useState } from 'react'
import styles from './Landing.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import { Link } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { motion, useAnimation, useInView, } from 'framer-motion';
import Lottie from 'lottie-react';

// import animationData from '../../../'

import classNames from 'classnames'


function Landing() {


  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    arrows: false,
    speed: 8000,
    cssEase: 'linear'
  };

  const animationData = require('../../Animation - 1708610209469.json');


  const bannerRef = useRef(null);
  const whiteRef = useRef(null);
  const purpleRef = useRef(null);
  const pinkRef = useRef(null);
  const blackRef = useRef(null);

  const patronsRef1 = useRef(null);
  const patronsRef2 = useRef(null);

  const [showHyperspace, setShowHyperspace] = useState<boolean>(true);

  const [emailsent, setEmailsent] = useState<any>(false);

  const hoverRefs = [{
    text: 'Identify and Explore Possibilities',
    imgUrl: 'hover1',
    img: useRef(null),
    p: useRef(null)
  },
  {
    text: 'Win on Marketplaces',
    imgUrl: 'hover2',
    img: useRef(null),
    p: useRef(null)
  },
  {
    text: 'Apply our Winning Formula',
    imgUrl: 'hover3',
    img: useRef(null),
    p: useRef(null)
  },
  {
    text: 'Find your Key to Success',
    imgUrl: 'hover4',
    img: useRef(null),
    p: useRef(null)
  },
  {
    text: 'Let us do our Magic',
    imgUrl: 'hover5',
    img: useRef(null),
    p: useRef(null)
  },
  ];

  console.log("Cavemen Studios - Studio as a Service Podcast for Business".length, "length")

  const bannerInView = useInView(bannerRef, { once: false });
  const whiteInView = useInView(whiteRef, { once: false });
  const purpleInView = useInView(purpleRef, { once: false });
  const pinkInView = useInView(pinkRef, { once: false });
  const blackInView = useInView(blackRef, { once: false });

  const [navbarstyle, setNavbarStyle] = useState({});
  const [submitted,setSubmitted]=useState<any>(false);

  useEffect(() => {
    const CANVAS: HTMLCanvasElement = document.getElementsByTagName("canvas")[0] as HTMLCanvasElement,
      CTX: CanvasRenderingContext2D = CANVAS.getContext("2d") as CanvasRenderingContext2D,
      W: number = window.innerWidth,
      H: number = window.innerHeight,
      XO: number = W / 2,
      YO: number = H / 2,
      NUM_PARTICLES: number = 400,
      MAX_Z: number = 2,
      MAX_R: number = 2,
      Z_SPD: number = 1,
      PARTICLES: any[] = [];

    class Particle {
      pos: Vector;
      vel: Vector;
      fill: string;
      stroke: string;

      constructor(x: number, y: number, z: number) {
        this.pos = new Vector(x, y, z);
        const X_VEL: number = 0, Y_VEL: number = 0, Z_VEL: number = -Z_SPD;
        this.vel = new Vector(X_VEL, Y_VEL, Z_VEL);
        this.vel.scale(0.01);
        this.fill = "rgba(255,255,255,0.3)";
        this.stroke = this.fill;
      }

      update(): void {
        this.pos.add(this.vel);
      }

      render(): void {
        const PIXEL: number[] = to2d(this.pos),
          X: number = PIXEL[0],
          Y: number = PIXEL[1],
          R: number = (MAX_Z - this.pos.z) / MAX_Z * MAX_R;

        if (X < 0 || X > W || Y < 0 || Y > H) this.pos.z = MAX_Z;

        this.update();
        CTX.beginPath();
        CTX.fillStyle = this.fill;
        CTX.strokeStyle = this.stroke;
        CTX.arc(X, PIXEL[1], R, 0, Math.PI * 2);
        CTX.fill();
        CTX.stroke();
        CTX.closePath();
      }
    }

    class Vector {
      x: number;
      y: number;
      z: number;

      constructor(x: number, y: number, z: number) {
        this.x = x;
        this.y = y;
        this.z = z;
      }

      add(v: Vector): void {
        this.x += v.x;
        this.y += v.y;
        this.z += v.z;
      }

      scale(n: number): void {
        this.x *= n;
        this.y *= n;
        this.z *= n;
      }
    }

    function to2d(v: Vector): number[] {
      const X_COORD: number = v.x - XO,
        Y_COORD: number = v.y - YO,
        PX: number = X_COORD / v.z,
        PY: number = Y_COORD / v.z;
      return [PX + XO, PY + YO];
    }

    function render(): void {
      for (let i = 0; i < PARTICLES.length; i++) {
        (PARTICLES[i] as Particle).render();
      }
    }

    function loop(): void {
      requestAnimationFrame(loop);
      CTX.fillStyle = "rgba(0,0,0,0.15)";
      CTX.fillRect(0, 0, W, H);
      render();
    }

    function createParticles(): void {
      for (let i = 0; i < NUM_PARTICLES; i++) {
        const X: number = Math.random() * W, Y: number = Math.random() * H, Z: number = Math.random() * MAX_Z;
        PARTICLES.push(new Particle(X, Y, Z));
      }
    }

    function init(): void {
      CANVAS.width = W;
      CANVAS.height = H;
      createParticles();
      loop();
    }
    init();


  }, [])

  useEffect(() => {
    if (bannerInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      setNavbarStyle({ position: 'relative' });
      setShowHyperspace(true);
    }

    if (whiteInView) {
      document.body.style.backgroundColor = '#1C1C1C';
      document.body.style.backgroundImage = "none";
      setNavbarStyle({ position: 'fixed' });
      setShowHyperspace(false);
    }
    if (purpleInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      setNavbarStyle({ position: 'fixed' });
    }

    if (pinkInView) {
      document.body.style.backgroundColor = '#1C1C1C';
      document.body.style.backgroundImage = "none";
      setNavbarStyle({ position: 'fixed' });
    }

    if (blackInView) {
      document.body.style.backgroundColor = '#0D0D0D'
      document.body.style.backgroundImage = "none";
      setNavbarStyle({ position: 'fixed' });
      return;
    }
  }, [whiteInView, purpleInView, pinkInView, blackInView]);


  const phoneRef = useRef<any>(null);
  const emailRef = useRef<any>(null);


  const handleSubmit = (e: any) => {
    e.preventDefault()

    setSubmitted(true)
   

    const email = emailRef.current.value;
    const phone = phoneRef.current.value;


    // console.log(values)
    var apiKey = "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";
    var senderEmail = email;


    var data = {
      sender: { email: senderEmail },
      to: [{ email: "ved@gohyper.space" }, { email: "kalyan.utti@gohyper.space" }, { email: "vinay@gohyper.space" },{email: "mission@gohyper.space"}],
      // cc: [{email: 'uttikalyan123@gmail.com'}, {email: 'bharshavardhini16@gmail.com'}],
      subject: "go hyperspace",
      templateId: 1,
      params: {
        name: "",
        email: email,
        phone: phone,
        message: ""
      }
    };

    const config: any = {
      method: "POST",
      url: 'https://api.sendinblue.com/v3/smtp/email',
      headers: {
        "Content-Type": "application/json",
        "api-key": apiKey
      },
      data: JSON.stringify(data)
    }
    axios(config)
      .then(res => {
        console.log("Email send ")

        if (res.status === 201) {
          emailRef.current.value = ''; 
          phoneRef.current.value = '';
          setEmailsent(true)
          setSubmitted(false)
          var contactData = {
            email: email,
            // listIds: [4],
            updateEnabled: true,
            attributes: {
              SOURCE: "ContactUs",
              FIRSTNAME: '',
              SMS: ``
            }
          };
          var config1: any = {
            method: "POST",
            url: 'https://api.brevo.com/v3/contacts',
            headers: {
              "Content-Type": "application/json",
              "api-key": apiKey
            },
            data: JSON.stringify(contactData)
          }

          axios(config1)
            .then(res => {
              console.log("data added sucessfully")
             
            })
            .catch(err => {
              console.log('something went wrong')
            })


        }
      })
      .catch(err => {
        console.log(err)
      })




  }


  function moveImage(container: any, image: any, event: any) {

    const imageWidth = image.current.offsetWidth;

    const mouseX = event.clientX - container.current.getBoundingClientRect().left;

    const translateValue = mouseX - imageWidth / 2;

    image.current.style.transform = `translateX(${translateValue}px)`;
  }

  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>Blitzscaling Consumer Brands | Omnichannel Retail & D2C Platform</title>

        <meta name="description" content="Scale brands faster. Full-stack growth partner. Omnichannel platform for orders, marketing & distribution." />

        <meta property="og:title" content="Blitzscaling Consumer Brands" />
        <meta property="og:description" content="Helping brands scale faster with full-stack business" />
        <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />


        <meta name="twitter:description" content="Helping brands scale faster with full-stack business" />
        <meta name="twitter:title" content="Blitzscaling Consumer Brands" />
        <meta name="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />

        <meta name="copyright" content="2024 https://www.gohyper.space" />
        <meta name="coverage" content="Worldwide" />

        <meta property="og:url" content="https://www.gohyper.space" />
        <meta property="og:site_name" content="https://www.gohyper.space" />



      </Helmet>
      <div className={styles.container} ref={bannerRef}>
        <canvas id='canvas' />
        <div className={styles.content}>
          <img
            src="https://assets.gohyper.space/web-landing/images/Group+5924.png"
            alt="planets"
            className={styles.animateUpDown}
            style={{ right: '8%', top: '20%', width: '8%' }}
          />

          <img
            src="/assets/images/planet3.png"
            alt="planets"
            className={styles.amimateRotate}
            style={{ left: '10%', top: '10%', width: '3%' }}
          />

          <img
            src="https://assets.gohyper.space/web-landing/images/Group+5923.png"
            alt="planets"
            className={styles.animateZoom}
            style={{ left: '40%', top: '8%', width: '2%' }}
          />

          <img
            src="/assets/images/planet2.png"
            alt="planets"
            className={styles.animateZoom}
            style={{ left: '20%', top: '64%', width: '5%' }}
          />

          <img
            src="/assets/images/planet1.png"
            alt="planets"
            className={styles.amimateRotate}
            style={{ right: '15%', top: '75%', width: '5%' }}
          />

          <img
            src="/assets/images/planet1.png"
            alt="planets"
            className={styles.amimateRotate}
            style={{ left: '5%', top: '80%', width: '1%' }}
          />

          <div className={styles.contentImgContainer}>
            <h1>The Future of <br />
              <span className={styles.retailIsHere}>Retail is here</span>
            </h1>

            <p>
              Take the next big step towards <b>limitless growth</b> with hyperspace.<br />
            </p>
            <p style={{ fontFamily: "Poopins-Italic" }}>Start today. It's free.</p>
          </div>
          <div className={classNames(styles.downArrow, { hidden: !showHyperspace, block: showHyperspace })}>
            <img alt='down' src='/assets/images/down-animation.gif' style={{ top: '0px' }} />
            <img alt='down' src='/assets/images/down-animation.gif' style={{ top: '20px' }} />
          </div>
          <Link to="/contact-us" className={classNames(styles.button, { hidden: !showHyperspace, block: showHyperspace })}>See <span><b>hyperspace.</b></span> in action</Link>
        </div>
        <Navbar style={navbarstyle} />
        <div className={styles.transformYourBusinessContainer}>
          <img src="/assets/images/white-planet1.png"
            alt="planet"
            className={styles.planet1}
          />
          <img src="/assets/images/white-planet2.png"
            alt="planet"
            className={styles.planet2}
          />
          <div ref={whiteRef} style={{ maxWidth: '100%' }}>
            <div className={styles.heading} style={{ backgroundColor: 'var(--white-bg)', marginBottom: '0px', margin: 'auto' }}>
              <h1>Transform your business</h1>
              <h1 className={styles.grayColorHeading}>with AI-powered tech</h1>
              <p>Built around AI, hyperspace allows you to do more in less time. It’s effective, super smart, </p>
              <p style={{ marginBottom: '30px' }}>and almost too good to be true. But don’t take just our word for it.</p>
              <button><Link to='/contact-us' style={{ textDecoration: 'none', color: '#FFFFFF' }}>Get Started</Link></button>
            </div>
            {/* <img src="/assets/images/carousels-gif.gif" alt="" style={{ marginTop: '70px' ,width:'1020px',maxWidth: '100%'}} /> */}

          </div>
          <div style={{ width: '1070px', maxWidth: '100%', margin: 'auto', padding: '10px' }} ref={whiteRef} >
            <video autoPlay loop muted style={{ width: '100%' }} playsInline data-wf-ignore="true" data-object-fit="cover">

              <source src="https://assets.gohyper.space/web_assets/videos/WhatsApp+Video+2024-02-23+at+16.21.37.mp4" type="video/mp4" />
            </video>
            {/* <Lottie
              animationData={animationData}
              loop={true}
              autoplay={true}
              style={{ width: '100%' }}
            /> */}
          </div>

          <div className={`${styles.noBsSection} ${styles.heading}`} style={{ maxWidth: '100%', backgroundColor: '#0D0D0D' }} >
            <h1 style={{ color: '#FFFFFF' }}>No BS,</h1>
            <h1 className={classNames(styles.justStraightTalk, styles.retailIsHere)}>just straight talk.</h1>
            <p style={{ color: '#DFDFDF' }}>Our easy-to-use platform gives you all the tools you need to manage your business in just a few clicks.</p>
            <p style={{ fontFamily: "Poopins-Italic", color: '#DFDFDF' }}> Skip scaling manually, use hyperspace instead.</p>
            {/* <p>Skip scaling manually, Use hyperspace instead</p> */}
          </div>
          <div className={styles.noBsContainer} ref={purpleRef} style={{ maxWidth: '100%' }} >
            <img src="/assets/images/blue-planet1.png"
              alt="planet"
              className={styles.planet3}
            />
            <img src="/assets/images/blue-planet2.png"
              alt="planet"
              className={styles.planet4}
            />
            <img src="/assets/images/blue-planet3.png"
              alt="planet"
              className={styles.planet5}
            />
            <div style={{ maxWidth: '100%' }}>
              <div className={styles.noBsItems} style={{ maxWidth: '100%' }}>
                <div className={styles.noBsItem1} style={{ maxWidth: '100%' }}>
                  <div>
                    <img src="assets/images/thunder-icon.png" alt="thunder-icon" />
                    <h2 style={{ marginBottom: '20px' }}>Unified Commerce Solution for All.</h2>
                    <p>Centralize your business operations by bringing orders, logistics, marketing, distribution etc, all-in-one dashboard.</p><br />
                    <p>Welcome to the future of how businesses are run.</p>
                    <div className={styles.knowMore}>
                      <Link to='/platform' style={{ textDecoration: 'none', color: '#FFFFFF' }}>Know More</Link>
                      <img src='/assets/images/know_more_icon.png' />
                    </div>

                  </div>
                  <img alt='icon' src='/assets/images/landing_dashboard.png' />
                </div>
                <div className={styles.column} >
                  <div className={styles.noBsItem2}>
                    <h2>Marketing Insights powered by AI.</h2>
                    <p>Connect and track all your marketing efforts across channels. Know your customers better, reduce CAC & get an insight & predictions at scale!</p>
                    <div className={styles.knowMore}>
                      <Link to='/marketing' style={{ textDecoration: 'none', color: '#FFFFFF' }}>Know More</Link>
                      <img src='/assets/images/know_more_icon.png' />
                    </div><br />
                    {/* <img alt='icon' src='/assets/images/Logo-1.gif' /> */}
                    <video autoPlay loop muted style={{ width: '70%' }} playsInline data-wf-ignore="true" data-object-fit="cover">
                      <source src="https://assets.gohyper.space/web_assets/videos/illustration1+final.mp4" type="video/mp4" />
                    </video>
                  </div>
                  <div className={styles.noBsItem2} style={{ backgroundColor: '#FE655D' }}>
                    <img alt='icon' src='/assets/images/group-18520.png' />
                    <h2 style={{ marginTop: '30px' }}>Real-time Analytics.</h2>
                    <p>Stay on top your business all the time with real-time reports on activities across the channels. Shipping, Marketing, Order realted reports at one place!</p>
                    <div className={styles.knowMore}>
                      <Link to='/marketing' style={{ textDecoration: 'none', color: '#FFFFFF' }}>Know More</Link>
                      <img src='/assets/images/know_more_icon.png' />
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.brandPartners} style={{ maxWidth: '100%' }}>
                <span>
                  <h1 style={{ color: '#FFFFFF' }}>Partners.</h1>
                  <h1 style={{ color: '#FFFFFF' }}>Brand</h1>
                  <img alt='icon' src='/assets/images/brand-white.png' />
                </span>
                <div className={styles.brandPartnersGrid} >
                  <Slider {...settings} vertical verticalSwiping >
                    <img alt='icon' src='/assets/images/brand1.png' />
                    {/* <img alt='icon' src='/assets/images/brand3.png' /> */}
                    <img alt='icon' src='/assets/images/brand5.png' />
                    <img alt='icon' src='/assets/images/brand43.png' />
                    <img alt='icon' src='/assets/images/brand6.png' />
                    <img alt='icon' src='/assets/images/brand8.png' />
                    {/* <img alt='icon' src='/assets/images/brand9.png' /> */}
                    {/* <img alt='icon' src='/assets/images/brand10.png' /> */}
                    <img alt='icon' src='/assets/images/brand13.png' />
                    {/* <img alt='icon' src='/assets/images/brand15.png' /> */}
                    <img alt='icon' src='/assets/images/brand16.png' />
                    <img alt='icon' src='/assets/images/brand17.png' />
                    <img alt='icon' src='/assets/images/brand18.png' />
                    {/* <img alt='icon' src='/assets/images/brand19.png' /> */}
                    <img alt='icon' src='/assets/images/brand20.png' />
                    <img alt='icon' src='/assets/images/brand47.png' />
                    <img alt='icon' src='/assets/images/brand48.png' />
                    <img alt='icon' src='/assets/images/amazon-shipping.png' />
                    {/* <img alt='icon' src='/assets/images/brand21.png' /> */}
                    {/* <img alt='icon' src='/assets/images/brand22.png' /> */}
                    {/* <img alt='icon' src='/assets/images/brand24.png' /> */}
                  </Slider>
                </div>
                <div className={classNames(styles.brandPartnersGrid, styles.brandPartnersGrid2)} >
                  <Slider {...settings} vertical verticalSwiping rtl >
                    <img alt='icon' src='/assets/images/brand1.png' />
                    {/* <img alt='icon' src='/assets/images/brand3.png' /> */}
                    <img alt='icon' src='/assets/images/brand5.png' />
                    <img alt='icon' src='/assets/images/brand43.png' />
                    <img alt='icon' src='/assets/images/brand6.png' />
                    <img alt='icon' src='/assets/images/brand8.png' />
                    {/* <img alt='icon' src='/assets/images/brand9.png' /> */}
                    {/* <img alt='icon' src='/assets/images/brand10.png' /> */}
                    <img alt='icon' src='/assets/images/brand13.png' />
                    {/* <img alt='icon' src='/assets/images/brand15.png' /> */}
                    <img alt='icon' src='/assets/images/brand16.png' />
                    <img alt='icon' src='/assets/images/brand17.png' />
                    <img alt='icon' src='/assets/images/brand18.png' />
                    {/* <img alt='icon' src='/assets/images/brand19.png' /> */}
                    <img alt='icon' src='/assets/images/brand20.png' />
                    <img alt='icon' src='/assets/images/brand47.png' />
                    <img alt='icon' src='/assets/images/brand48.png' />
                    <img alt='icon' src='/assets/images/amazon-shipping.png' />
                    {/* <img alt='icon' src='/assets/images/brand21.png' /> */}
                    {/* <img alt='icon' src='/assets/images/brand22.png' /> */}
                    {/* <img alt='icon' src='/assets/images/brand24.png' /> */}
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.sellWhever} style={{ maxWidth: '100%' }}>
            <h1 style={{ color: '#FFFFFF' }}>Sell wherever,<br /><span className={classNames(styles.retailIsHere, styles.yourCustomersAre)}>your customers are.</span></h1>
          </div>
          <div ref={pinkRef} style={{ maxWidth: '100%' }}>
            <img src="/assets/images/red-planet1.png"
              alt="planet"
              className={styles.planet6}
            />
            <img src="/assets/images/red-planet2.png"
              alt="planet"
              className={styles.planet7}
            />
            <img src="/assets/images/red-planet3.png"
              alt="planet"
              className={styles.planet8}
            />
            <img src="/assets/images/red-planet4.png"
              alt="planet"
              className={styles.planet9}
            />
            <img src="/assets/images/red-planet5.png"
              alt="planet"
              className={styles.planet10}
            />
            <div className={styles.sellWhereverContainer} style={{ maxWidth: '100%' }}>
              <div style={{ maxWidth: '100%' }}>
                <div className={styles.sellWhereverItems} style={{ maxWidth: '100%' }}>

                  <div className={styles.column} >
                    <div className={styles.sellWhereverItem2}>
                      <h2>Plug ‘n’ Play Distribution Solution.</h2>
                      <p> Manage all your marketplaces and offline distribution channels.</p>
                      <p>With hyperspace, get your products listed on both Online & Offline marketplaces! </p>
                      <div className={styles.knowMore}>
                        <Link to='/distribution' style={{ textDecoration: 'none', color: '#FFFFFF' }}>Know More</Link>
                        <img src='/assets/images/know_more_icon.png' />
                      </div><br />
                      {/* <img alt='icon' src='/assets/images/Logo-2-Aniamation.gif' /> */}
                      <video autoPlay loop muted style={{ width: '100%' }} playsInline data-wf-ignore="true" data-object-fit="cover">
                        <source src="https://assets.gohyper.space/web_assets/videos/illustration+2+final.mp4" type="video/mp4" />
                      </video>
                    </div>
                    <div className={styles.sellWhereverItem2} style={{ backgroundColor: '#FE938E' }}>
                      <img alt='icon' src='/assets/images/logistics.png' />
                      <h2 style={{ marginTop: '30px' }}>Optimized Logistics Solution.</h2>
                      <p>
                        No more juggling between multiple courier partners, One dashboard to ship with ALL. The best part ZERO extra cost.</p>

                      <p>Identity ‘authentic’ orders and fulfill them on-the-go.</p>

                      <div className={styles.knowMore}>
                        <Link to='/distribution' style={{ textDecoration: 'none', color: '#FFFFFF' }}>Know More</Link>
                        <img src='/assets/images/know_more_icon.png' />
                      </div>
                    </div>
                  </div>
                  <div className={styles.sellWhereverItem1} style={{ maxWidth: '100%' }}>
                    <h2 style={{ color: '#FFFFFF' }}><img alt='icon' src='/assets/images/patron-white.png' /> Our Patrons.</h2>
                    <div className={styles.ourPatronsContainer}>
                      <Slider {...settings} rtl ref={patronsRef1} className={styles.carouselContainer}>
                        <img alt='icon' src='/assets/images/patron1.png' />
                        <img alt='icon' src='/assets/images/patron2.png' />
                        <img alt='icon' src='/assets/images/patron3.png' />
                        <img alt='icon' src='/assets/images/patron4.png' />
                        <img alt='icon' src='/assets/images/patron5.png' />
                        <img alt='icon' src='/assets/images/patron6.png' />
                        <img alt='icon' src='/assets/images/patron7.png' />
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className={styles.howWeGrow} >
              <div>
                <h1>How we <br /> grow your <br /> <span className={styles.brand}>brand.</span></h1><br />
                <span className={styles.brandDescription}>Our mission is to revolutionize the way great products are brought into the world. With our expertise, we transform local brands into household names easily, intelligently, and quickly.</span>
              </div>
              <div>
                {
                  hoverRefs.map(obj => (
                    <p key={obj.text} onMouseMove={(e) => moveImage(obj.p, obj.img, e)} ref={obj.p}>
                      {obj.text}
                      <img width='300px' src={`/assets/images/${obj.imgUrl}.png`} alt="Image Description" ref={obj.img} />
                    </p>
                  ))
                }
              </div>
            </div>

            <h1 className={styles.heading} style={{ color: '#FFFFFF' }} >It feels good to be <span style={{ color: '#FF725E', marginTop: '120px' }} className={styles.retailIsHere}>loved</span></h1>
            <div className={styles.sliderContainer}>
              <Slider slidesToShow={1} dots arrows={false} autoplay autoplaySpeed={1000} infinite speed={5000}>
                <div>
                  <div className={styles.testimonialContainer}>
                    <div>
                      <img alt='nutlicious' src='/assets/images/nut-licious.png' />
                      <p style={{ marginTop: '40px', marginBottom: '20px' }}>How omnichannel presence led to increase in brand recall for Nut-O-Licious</p>
                      <div style={{ width: '100%', gap: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        <button style={{ margin: '0px' }}><Link to='/marketing' style={{ textDecoration: 'none', color: 'var(--white-text)' }}>Marketing</Link></button>
                        <button style={{ margin: '0px' }}><Link to='/marketing' style={{ textDecoration: 'none', color: 'var(--white-text)' }}>Distribution</Link></button>
                        <button style={{ margin: '0px' }}>All-in-one Dashboard</button>
                      </div>
                    </div>
                    <div style={{ border: '1px solid #E1DEE7', borderRadius: '20px' }}>
                      <img alt='nutlicious-owner' src='/assets/images/nut-licious-owner.png' />
                      <span>Anudeep Chappa, Co-Founder</span>
                      <p>
                        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.206 0.835998L6.056 11.756L4.922 7.22C6.322 7.22 7.47 7.626 8.366 8.438C9.262 9.25 9.71 10.37 9.71 11.798C9.71 13.226 9.248 14.374 8.324 15.242C7.428 16.082 6.294 16.502 4.922 16.502C3.55 16.502 2.402 16.082 1.478 15.242C0.582 14.374 0.134 13.226 0.134 11.798C0.134 11.406 0.162 11.014 0.218 10.622C0.302 10.23 0.442 9.754 0.638 9.194C0.834 8.634 1.128 7.906 1.52 7.01L4.208 0.835998H9.206ZM22.6181 0.835998L19.4681 11.756L18.3341 7.22C19.7341 7.22 20.8821 7.626 21.7781 8.438C22.6741 9.25 23.1221 10.37 23.1221 11.798C23.1221 13.226 22.6601 14.374 21.7361 15.242C20.8401 16.082 19.7061 16.502 18.3341 16.502C16.9621 16.502 15.8141 16.082 14.8901 15.242C13.9941 14.374 13.5461 13.226 13.5461 11.798C13.5461 11.406 13.5741 11.014 13.6301 10.622C13.7141 10.23 13.8541 9.754 14.0501 9.194C14.2461 8.634 14.5401 7.906 14.9321 7.01L17.6201 0.835998H22.6181Z" fill="#6C6868" />
                        </svg>
                        &nbsp;&nbsp;We teamed up with hyperspace for distribution mainly, but they beyond & turned Nut-O-Licious into a standout brand!
                      </p>
                      <p>
                        Thanks guys, for making our cashews seriously cool!&nbsp;&nbsp;
                        <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.822 0.867998L19.272 12.828L20.514 7.86C18.9807 7.86 17.7233 8.30466 16.742 9.194C15.7607 10.0833 15.27 11.31 15.27 12.874C15.27 14.438 15.776 15.6953 16.788 16.646C17.7693 17.566 19.0113 18.026 20.514 18.026C22.0167 18.026 23.274 17.566 24.286 16.646C25.2673 15.6953 25.758 14.438 25.758 12.874C25.758 12.4447 25.7273 12.0153 25.666 11.586C25.574 11.1567 25.4207 10.6353 25.206 10.022C24.9913 9.40867 24.6693 8.61133 24.24 7.63L21.296 0.867998H15.822ZM1.13255 0.867998L4.58255 12.828L5.82455 7.86C4.29121 7.86 3.03388 8.30466 2.05255 9.194C1.07121 10.0833 0.580546 11.31 0.580546 12.874C0.580546 14.438 1.08655 15.6953 2.09855 16.646C3.07988 17.566 4.32188 18.026 5.82455 18.026C7.32721 18.026 8.58455 17.566 9.59655 16.646C10.5779 15.6953 11.0685 14.438 11.0685 12.874C11.0685 12.4447 11.0379 12.0153 10.9765 11.586C10.8845 11.1567 10.7312 10.6353 10.5165 10.022C10.3019 9.40867 9.97988 8.61133 9.55055 7.63L6.60655 0.867998H1.13255Z" fill="#6C6868" />
                        </svg>
                      </p>

                    </div>
                  </div>
                </div>

                <div>
                  <div className={styles.testimonialContainer}>
                    <div>
                      <img alt='nutlicious' src='https://assets.gohyper.space/web_assets/videos/madape-logo.avif' />
                      <p style={{ marginTop: '40px', marginBottom: '20px' }}>Improved Margins & ROAS by Optimising Performance Marketing Spends</p>
                      <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>

                        <button style={{ margin: '0px' }}><Link to='/marketing' style={{ textDecoration: 'none', color: 'var(--white-text)' }}>Performance Marketing</Link></button>
                        <button style={{ margin: '0px' }}> <Link to='/distribution' style={{ textDecoration: 'none', color: 'var(--white-text)' }}>Omnichannel Platform</Link></button>
                        <button style={{ margin: '0px' }}><Link to='/' style={{ textDecoration: 'none', color: 'var(--white-text)' }}>All-in-one Dashboard</Link></button>
                      </div>
                    </div>
                    <div style={{ border: '1px solid #E1DEE7', borderRadius: '20px' }}>

                      <img alt='nutlicious-owner' src='/assets/images/Santhosh-Raja.png' />
                      <span>Santhosh Raja, Founder</span>
                      <p>
                        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.206 0.835998L6.056 11.756L4.922 7.22C6.322 7.22 7.47 7.626 8.366 8.438C9.262 9.25 9.71 10.37 9.71 11.798C9.71 13.226 9.248 14.374 8.324 15.242C7.428 16.082 6.294 16.502 4.922 16.502C3.55 16.502 2.402 16.082 1.478 15.242C0.582 14.374 0.134 13.226 0.134 11.798C0.134 11.406 0.162 11.014 0.218 10.622C0.302 10.23 0.442 9.754 0.638 9.194C0.834 8.634 1.128 7.906 1.52 7.01L4.208 0.835998H9.206ZM22.6181 0.835998L19.4681 11.756L18.3341 7.22C19.7341 7.22 20.8821 7.626 21.7781 8.438C22.6741 9.25 23.1221 10.37 23.1221 11.798C23.1221 13.226 22.6601 14.374 21.7361 15.242C20.8401 16.082 19.7061 16.502 18.3341 16.502C16.9621 16.502 15.8141 16.082 14.8901 15.242C13.9941 14.374 13.5461 13.226 13.5461 11.798C13.5461 11.406 13.5741 11.014 13.6301 10.622C13.7141 10.23 13.8541 9.754 14.0501 9.194C14.2461 8.634 14.5401 7.906 14.9321 7.01L17.6201 0.835998H22.6181Z" fill="#6C6868" />
                        </svg>
                        &nbsp;&nbsp;In just three months, their expertise in Performance Marketing significantly improved our ROAS. Their strategies not only optimized our ad spend but also elevated our campaign outcomes, marking a pivotal shift in our business growth and customer reach.<svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.822 0.867998L19.272 12.828L20.514 7.86C18.9807 7.86 17.7233 8.30466 16.742 9.194C15.7607 10.0833 15.27 11.31 15.27 12.874C15.27 14.438 15.776 15.6953 16.788 16.646C17.7693 17.566 19.0113 18.026 20.514 18.026C22.0167 18.026 23.274 17.566 24.286 16.646C25.2673 15.6953 25.758 14.438 25.758 12.874C25.758 12.4447 25.7273 12.0153 25.666 11.586C25.574 11.1567 25.4207 10.6353 25.206 10.022C24.9913 9.40867 24.6693 8.61133 24.24 7.63L21.296 0.867998H15.822ZM1.13255 0.867998L4.58255 12.828L5.82455 7.86C4.29121 7.86 3.03388 8.30466 2.05255 9.194C1.07121 10.0833 0.580546 11.31 0.580546 12.874C0.580546 14.438 1.08655 15.6953 2.09855 16.646C3.07988 17.566 4.32188 18.026 5.82455 18.026C7.32721 18.026 8.58455 17.566 9.59655 16.646C10.5779 15.6953 11.0685 14.438 11.0685 12.874C11.0685 12.4447 11.0379 12.0153 10.9765 11.586C10.8845 11.1567 10.7312 10.6353 10.5165 10.022C10.3019 9.40867 9.97988 8.61133 9.55055 7.63L6.60655 0.867998H1.13255Z" fill="#6C6868" />
                        </svg>
                      </p>


                    </div>
                  </div>
                </div>

                {/* <div>
                  <div className={styles.testimonialContainer}>
                    <div>
                      <img alt='nutlicious' src='/assets/images/nut-licious.png' />
                      <p style={{ marginTop: '40px' }}>How omnichannel presence led to increase in brand recall for Nut-O-Licious</p>
                      <div style={{ width: '100%', gap: '20px', display: 'flex', flexDirection: 'row' }}>
                        <button><Link to='/marketing' style={{ textDecoration: 'none', color: 'var(--black-text)' }}>Marketing</Link></button>
                        <button><Link to='/marketing' style={{ textDecoration: 'none', color: 'var(--black-text)' }}>Distribution</Link></button>
                        <button>All-in-one Dashboard</button>
                      </div>
                    </div>
                    <div>
                      <img alt='nutlicious-owner' src='/assets/images/nut-licious-owner.png' />
                      <span>Anudeep Chappa, Co-Founder</span>
                      <p>
                        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.206 0.835998L6.056 11.756L4.922 7.22C6.322 7.22 7.47 7.626 8.366 8.438C9.262 9.25 9.71 10.37 9.71 11.798C9.71 13.226 9.248 14.374 8.324 15.242C7.428 16.082 6.294 16.502 4.922 16.502C3.55 16.502 2.402 16.082 1.478 15.242C0.582 14.374 0.134 13.226 0.134 11.798C0.134 11.406 0.162 11.014 0.218 10.622C0.302 10.23 0.442 9.754 0.638 9.194C0.834 8.634 1.128 7.906 1.52 7.01L4.208 0.835998H9.206ZM22.6181 0.835998L19.4681 11.756L18.3341 7.22C19.7341 7.22 20.8821 7.626 21.7781 8.438C22.6741 9.25 23.1221 10.37 23.1221 11.798C23.1221 13.226 22.6601 14.374 21.7361 15.242C20.8401 16.082 19.7061 16.502 18.3341 16.502C16.9621 16.502 15.8141 16.082 14.8901 15.242C13.9941 14.374 13.5461 13.226 13.5461 11.798C13.5461 11.406 13.5741 11.014 13.6301 10.622C13.7141 10.23 13.8541 9.754 14.0501 9.194C14.2461 8.634 14.5401 7.906 14.9321 7.01L17.6201 0.835998H22.6181Z" fill="#6C6868" />
                        </svg>
                        &nbsp;&nbsp;We teamed up with hyperspace for distribution mainly, but they beyond & turned Nut-O-Licious into a standout brand!
                      </p>
                      <p>
                        Thanks guys, for making our cashews seriously cool!&nbsp;&nbsp;
                        <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.822 0.867998L19.272 12.828L20.514 7.86C18.9807 7.86 17.7233 8.30466 16.742 9.194C15.7607 10.0833 15.27 11.31 15.27 12.874C15.27 14.438 15.776 15.6953 16.788 16.646C17.7693 17.566 19.0113 18.026 20.514 18.026C22.0167 18.026 23.274 17.566 24.286 16.646C25.2673 15.6953 25.758 14.438 25.758 12.874C25.758 12.4447 25.7273 12.0153 25.666 11.586C25.574 11.1567 25.4207 10.6353 25.206 10.022C24.9913 9.40867 24.6693 8.61133 24.24 7.63L21.296 0.867998H15.822ZM1.13255 0.867998L4.58255 12.828L5.82455 7.86C4.29121 7.86 3.03388 8.30466 2.05255 9.194C1.07121 10.0833 0.580546 11.31 0.580546 12.874C0.580546 14.438 1.08655 15.6953 2.09855 16.646C3.07988 17.566 4.32188 18.026 5.82455 18.026C7.32721 18.026 8.58455 17.566 9.59655 16.646C10.5779 15.6953 11.0685 14.438 11.0685 12.874C11.0685 12.4447 11.0379 12.0153 10.9765 11.586C10.8845 11.1567 10.7312 10.6353 10.5165 10.022C10.3019 9.40867 9.97988 8.61133 9.55055 7.63L6.60655 0.867998H1.13255Z" fill="#6C6868" />
                        </svg>
                      </p>

                    </div>
                  </div>
                </div> */}
              </Slider>
            </div>

            <div className={styles.growthFormula}>
              <div>
                <h1>Know Our</h1>
                <h1 className={styles.retailIsHere}>Growth Formula!</h1>
                <p>Your Guide to building a successful <br /> Retail strategy eBook!</p>
                <form onSubmit={handleSubmit}>
                  <input placeholder='Email Id' type='email' ref={emailRef} required />
                  <input placeholder='Mobile Number' type='number' ref={phoneRef} required />
                  <button type='submit'>{submitted?"Submitting":"Submit"}</button>
                </form>
                {emailsent ? <p style={{ color: 'green',fontFamily: "Poopins-Regular"}}>Thanks for Your Interest</p> : null}
              </div>
              <img alt='growth-icon' src='/assets/images/frame772544725.png' />
            </div>
          </div>
          <div className={styles.heading} style={{ backgroundColor: '#0D0D0D', maxWidth: '100%' }}>
            <h1 style={{ color: '#A1A1A1', paddingTop: '10px' }} >Skip scaling manually,</h1>
            <h1 style={{ color: '#FFFFFF', paddingBottom: '10px' }}>Use hyperspace instead</h1>
          </div>
          <div ref={blackRef} style={{ maxWidth: '100%' }} >
            <img src="/assets/images/black-planet1.png"
              alt="planet"
              className={styles.planet11}
            />
            <img src="/assets/images/black-planet2.png"
              alt="planet"
              className={styles.planet12}
            />
            <img src="/assets/images/black-planet3.png"
              alt="planet"
              className={styles.planet13}
            />
            <div className={styles.useHyperspace} style={{ maxWidth: '100%' }}>
              <div>
                <h1>Build the brand your product deserves.</h1>
                <Link to='/contact-us' style={{ textDecoration: 'none', color: '#FFFFFF',display:'flex',justifyContent:'flex-start'}}>
                <img alt='arrow-icon' src='/assets/images/white-arrow.png'/>

                </Link>

                



              </div>
              {/* <img alt='hyperspace' src='/assets/images/build-brand-gif.gif' style={{borderRadius:'30px'}} /> */}
              <div style={{ background: 'black' }}>
                <video autoPlay loop muted style={{ width: '100%', objectFit: 'contain' }} playsInline data-wf-ignore="true" data-object-fit="cover">
                  <source src="https://assets.gohyper.space/web_assets/videos/Render.mp4" type="video/mp4" />
                </video>
              </div>
            </div>

            <div className={styles.questionsPerk} style={{ maxWidth: '100%' }}>
              <img alt='questions' src='/assets/images/questions.png' />
              <img alt='perks' src='/assets/images/perks.png' />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Landing
