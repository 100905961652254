import React, { useRef, useEffect, useState } from 'react'
import styles from './Logistics.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';
import { useInView } from 'framer-motion'
import { Link } from 'react-router-dom';

function Logistics() {

  const bannerRef = useRef(null);
  const whiteRef = useRef(null);
  const purpleRef = useRef(null);
  const pinkRef = useRef(null);
  const blackRef = useRef(null);

  const bannerInView = useInView(bannerRef, { once: false });
  const whiteInView = useInView(whiteRef, { once: false });
  const purpleInView = useInView(purpleRef, { once: false });
  const pinkInView = useInView(pinkRef, { once: false });
  const blackInView = useInView(blackRef, { once: false });

  const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })

  useEffect(() => {
    if (bannerInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'relative' });
    }

    if (whiteInView) {
      document.body.style.backgroundColor = '#FFFFFF';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }
    if (purpleInView) {
      document.body.style.backgroundColor = '#1C1C1C';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

    if (pinkInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

    if (blackInView) {
      document.body.style.backgroundColor = '#1C1C1C'
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

  }, [whiteInView, purpleInView, pinkInView, blackInView]);


  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>eCommerce Courier & Shipping Solution | Aggregator at Zero Cost</title>
        <meta name="description" content="Ship your orders from any platform with India's leading courier partners at ZERO extra cost. Free Platform forever!" />

        <meta property="og:title" content="eCommerce Courier & Shipping Solution | Aggregator at Zero Cost" />
        <meta property="og:description" content="Ship your orders from any platform with India's leading courier partners at ZERO extra cost. Free Platform forever!" />
        <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

        <meta name="twitter:title" content="eCommerce Courier & Shipping Solution | Aggregator at Zero Cost" />
        <meta name="twitter:description" content="Ship your orders from any platform with India's leading courier partners at ZERO extra cost. Free Platform forever!" />
        <meta property="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />


      </Helmet>
      <Navbar style={navbarstyle} />

      <div className={styles.container}>
        <img
          src="/assets/images/saas-planet.png"
          alt="planets"
          className={styles.planet14}
        />
        <img
          src="/assets/images/planet3.png"
          alt="planets"
          className={styles.planet15}
        />
        <img
          src="/assets/images/blue-planet1.png"
          alt="planets"
          className={styles.planet16}
        />
        <img
          src="/assets/images/saas-planet2.png"
          alt="planets"
          className={styles.planet17}
        />
        <img
          src="/assets/images/blue-planet2.png"
          alt="planets"
          className={styles.planet18}
        />
        <img
          src="/assets/images/logistic-planet1.png"
          alt="planets"
          className={styles.planet19}
        />
        <img
          src="/assets/images/logistic-planet2.png"
          alt="planets"
          className={styles.planet20}
        />
        <img
          src="/assets/images/red-planet5.png"
          alt="planets"
          className={styles.planet21}
        />
        <img
          src="/assets/images/black-planet2.png"
          alt="planets"
          className={styles.planet22}
        />
        <img
          src="/assets/images/black-planet2.png"
          alt="planets"
          className={styles.planet23}
        />
        <div className={styles.content}>
          <div className={styles.contentImgContainer} ref={bannerRef}>
            <h1>Grow your eCommerce <br /> Business with our <br />
              <span className={styles.retailIsHere}>End-to-end Logistics <br /> Solution</span>
            </h1>
            {/* <h1 className={styles.retailIsHere}></h1> */}
            <p>
              Access 10+ top courier services with ZERO added fees!
            </p>
            <p style={{ marginTop: '5px' }}>Enhance your logistical capabilities with budget-friendly options and nation-wide coverage.</p>
            <button><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></button>
          </div>
        </div>
        <div style={{ backgroundColor: '#1C1C1C' }} className={styles.heading}>
          <h1><span className={styles.gradient}>Get Control & Visibility</span><br /> on All Your Orders</h1>
          <p>Empower your business with streamlined order management and real-time tracking capabilities.</p>
        </div>


        <div className={styles.main}>
          <div ref={purpleRef} className={styles.getControl}>
            <div className={styles.column} >
              <div className={styles.getControlItems}>
                <img alt='courier-icon' src='/assets/images/managment-icon.png' />
                <h2>Centralized Order Management</h2>

                <p>Manage your orders from multiple sales channels using ONE platform. </p>

                <p>Just connect & Enjoy :)</p>
              </div>

              <div className={styles.getControlItems}>
                <img alt='courier-icon' src='/assets/images/courier-icon.png' />
                <h2>Intelligent Courier Recommendation</h2>
                <p>With 1000’s data points, we know which courier suits best for your orders. </p>
                <p>Performance Rating for Couriers!</p>
              </div>

              <div className={styles.getControlItems}>
                <img alt='courier-icon' src='/assets/images/shipping-icon.png' />
                <h2>Discounted Shipping Rates</h2>
                <p>Ship with leading courier partners at discounted rates.</p>
                <p>ZERO platform charges!</p>
              </div>


            </div>
            <div className={styles.shippingHeading}>
              <h1 style={{ color: 'var(--white-text)' }} ><span className={styles.gradient}>Shipping Solution</span> <br /> Built for eCommerce</h1>
              <p style={{ fontFamily: 'Poopins-Regular' }}>Transform how you ship your products and scale your business in no time!</p>
            </div>


            <div className={styles.column}>
              <div className={styles.courierItems}>
                <h2>Seamless COD remittance</h2>
                <span style={{
                  textAlign: 'left',
                  // position: 'absolute',
                  top: '100px',
                  left: '30px'
                }}>Stop chasing COD payments from courier partners</span>
                <img alt='cod-icon' src='/assets/images/cod-icon.png' style={{ position: 'absolute', right: '20px', bottom: '20px' }} />
              </div>
              <div className={styles.courierItems}>
                <h2>Multiple Pickup Locations</h2>
                <span style={{ width: '70%', maxWidth: '100%' }}>Ship more orders and scale faster with our vast network</span>
                <img style={{ position: 'absolute', right: '20px', bottom: '20px' }} alt='pickup-icon' src='/assets/images/pickup-icon.png' />
              </div>
              <div className={styles.courierItems}>
                <h2>Smarter NDR, <br />Lower RTO</h2>
                <span>Automated NDR reporting  to reduce RTO rates</span>
                <img style={{ position: 'absolute', bottom: '40px', left: '50px', width: '80%' }} alt='ndr-icon' src='/assets/images/ndr-icon.png' />
              </div>
              <div className={styles.courierItems}>
                <h2>Frictionless Deliveries</h2>
                <span>Have complete visibility of all <br /> delivery stages</span>
                <img style={{ position: 'absolute', bottom: '20px', left: '50px', width: '80%' }} alt='devliveries-icon' src='/assets/images/deliveries-icon.png' />
              </div>
            </div>
          </div>

          <div style={{ width: '1020px', margin: '100px auto', maxWidth: '100%', backgroundColor: '#0D0D0D', borderRadius: '30px', padding: '40px' }}>
            <h1 style={{ margin: '0px', padding: '0px', color: 'var(--white-bg)' }}>
              <span className={styles.gradient}>Delight Your Customers </span>  With Flexible Delivery Options
            </h1>
            <p style={{ fontFamily: 'Poopins-Regular', marginTop: '10px', color: '#FFFFFF' }}>Ship at your optimized costs and delivery timelines of your choice with our extensive network of courier partners.The best part you can provide a brand-ed experience of your own!</p>
          </div>

          <div ref={pinkRef} className={styles.reachCustomers}>
            <div className={styles.column}>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>Branded Experience</h2>
                <span>Increase customer delight & brand recall with customized alerts and purchase tracking.</span>
              </div>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>Same-Day & 24hrs Delivery</h2>
                <span>Ensure on-time deliveries in every order and keep customers happy!</span>
              </div>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>24*7 Customer Support</h2>
                <span>Keep the complaints in control with our support</span>
              </div>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>Improved Costs</h2>
                <span>Never go beyond budget with courier partners who offer the best prices</span>
              </div>
            </div>
          </div>

          <div className={styles.leadingCouriersHeading}>
            <h1>
              Ship with 10+ Leading Couriers
            </h1>
            <p ref={blackRef}>Choose the best courier using AI & access delivery across 27000+ pin codes.</p>
          </div>

          <div className={styles.leadingCouriers}>
            <button><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></button>
          </div>


        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Logistics;
