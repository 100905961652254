import React, { useState } from 'react'
import styles from './TermsandConditons.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

function TermsandConditions() {
    const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })
    return (
        <div className={styles.mainContainer}>
            <Navbar style={navbarstyle} />
            <div className={styles.content}>
                <h1>Terms of Service</h1>
                <p>Last updated: February 24, 2024</p>
                <h2>AGREEMENT TO OUR LEGAL TERMS</h2>
                <p>TWe are Hyperspace retail labs private limited ("Company," "we," "us," "our"), a company registered
                    in India at hyperspace HQ, Dwaraka park view, , Hitech City , Telangana 500081.</p>
                <p>
                    We operate the website gohyper.space (the "Site"), as well as any other related products and services that
                    refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").
                </p>
                <p>
                    You can contact us by phone at +91 96663 85000, email at support@gohyper.space, or by mail to hyperspace
                    HQ, Dwaraka park view, , Hitech City , Telangana 500081, India.
                </p>
                <p>
                    These Legal Terms constitute a legally binding agreement made between you, whether personally or on
                    behalf of an entity ("you"), and Hyperspace retail labs private limited , concerning your access to and use of
                    the Services. You agree that by accessing the Services, you have read, understood, and agreed to be
                    bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN
                    YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
                    USE IMMEDIATELY.
                </p>
                <p>
                    Supplemental terms and conditions or documents that may be posted on the Services from time to time are
                    hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make
                    changes or modifications to these Legal Terms at any time and for any reason. We will alert you about any
                    changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive
                    specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay
                    informed of updates. You will be subject to, and will be deemed to have been made aware of and to have
                    accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such
                    revised Legal Terms are posted.
                </p>
                <p>
                    The Services are intended for users who are at least 13 years of age. All users who are minors in the
                    jurisdiction in which they reside (generally under the age of 18) must have the permission of, and be directly
                    supervised by, their parent or guardian to use the Services. If you are a minor, you must have your parent or
                    guardian read and agree to these Legal Terms prior to you using the Services.
                </p>
                <h2>1. OUR SERVICES</h2>
                <p>The information provided when using the Services is not intended for distribution to or use by any person
                    or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation
                    or which would subject us to any registration requirement within such jurisdiction or country. Accordingly,
                    those persons who choose to access the Services from other locations do so on their own initiative and
                    are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                </p>
                <h2>
                    2. INTELLECTUAL PROPERTY RIGHTS
                </h2>
                <h3>Our intellectual property</h3>
                <p>
                    We are the owner or the licensee of all intellectual property rights in our Services, including all source code,
                    databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the
                    Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein
                    (the "Marks").
                </p>
                <p>
                    Our Content and Marks are protected by copyright and trademark laws (and various other intellectual
                    property rights and unfair competition laws) and treaties in the United States and around the world.
                </p>
                <p>
                    The Content and Marks are provided in or through the Services "AS IS" for your internal business
                    purpose only.
                </p>
                <h2>
                    3. USER REPRESENTATIONS
                </h2>
                <p>
                    By using the Services, you represent and warrant that: (1) all registration information you submit will be true,
                    accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update
                    such registration information as necessary; (3) you have the legal capacity and you agree to comply with
                    these Legal Terms; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction in which
                    you reside, or if a minor, you have received parental permission to use the Services; (6) you will not access the
                    Services through automated or non-human means, whether through a bot, script or otherwise; (7) you will
                    not use the Services for any illegal or unauthorized purpose; and (8) your use of the Services will not violate
                    any applicable law or regulation.
                </p>
                <p>
                    If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
                    suspend or terminate your account and refuse any and all current or future use of the Services (or any
                    portion thereof).
                </p>
                <h2>
                    4. USER REGISTRATION
                </h2>
                <p>
                    You may be required to register to use the Services. You agree to keep your password confidential and will
                    be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change
                    a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene,
                    or otherwise objectionable
                </p>
                <h2>
                    5. PURCHASES AND PAYMENT
                </h2>
                <p>We accept the following forms of payment:</p>
                <ul>
                    <li>
                        <p>Visa</p>
                    </li>
                    <li>
                        <p>Mastercard</p>
                    </li>

                </ul>
                <p>
                    You agree to provide current, complete, and accurate purchase and account information for all purchases
                    made via the Services. You further agree to promptly update account and payment information, including
                    email address, payment method, and payment card expiration date, so that we can complete your
                    transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed
                    required by us. We may change prices at any time. All payments shall be in Rupees .
                </p>
                <p>
                    You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping
                    fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your
                    order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or
                    received payment.
                </p>
                <p>
                    We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or
                    cancel quantities purchased per person, per household, or per order. These restrictions may include orders
                    placed by or under the same customer account, the same payment method, and/or orders that use the
                    same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment,
                    appear to be placed by dealers, resellers, or distributors
                </p>
                <h2>6. PROHIBITED ACTIVITIES</h2>
                <p>You may not access or use the Services for any purpose other than that for which we make the Services
                    available. The Services may not be used in connection with any commercial endeavors except those that
                    are specifically endorsed or approved by us.</p>
                <p>As a user of the Services, you agree not to:</p>
                <ul>
                    <li>
                        <p>Systematically retrieve data or other content from the Services to create or compile, directly or
                            indirectly, a collection, compilation, database, or directory without written permission from us.</p>
                    </li>
                    <li>
                        <p>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                            information such as user passwords</p>
                    </li>
                    <li>
                        <p>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</p>
                    </li>
                    <li>
                        <p>Use any information obtained from the Services in order to harass, abuse, or harm another person.</p>
                    </li>
                    <li>
                        <p>Make improper use of our support services or submit false reports of abuse or misconduct.</p>
                    </li>
                </ul>
                <h2>7. USER GENERATED CONTRIBUTIONS</h2>
                <p>
                    The Services does not offer users to submit or post content. We may provide you with the opportunity to
                    create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us
                    or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments,
                    suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be
                    viewable by other users of the Services and through third-party websites. As such, any Contributions you
                    transmit may be treated in accordance with the Services' Privacy Policy. When you create or make available
                    any Contributions, you thereby represent and warrant that:
                </p>
                <ul>
                    <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
                        copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to
                        the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                    <li>You are the creator and owner of or have the necessary licenses, rights, consents, releases, and
                        permissions to use and to authorize us, the Services, and other users of the Services to use your
                        Contributions in any manner contemplated by the Services and these Legal Terms.</li>
                    <li>You have the written consent, release, and/or permission of each and every identifiable individual person in
                        your Contributions to use the name or likeness of each and every such identifiable individual person to
                        enable inclusion and use of your Contributions in any manner contemplated by the Services and these
                        Legal Terms</li>
                    <li>Your Contributions are not false, inaccurate, or misleading.</li>
                    <li>Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid
                        schemes, chain letters, spam, mass mailings, or other forms of solicitation.</li>
                </ul>

                <h2>8. CONTRIBUTION LICENSE</h2>
                <p>You and Services agree that we may access, store, process, and use any information and personal data that
                    you provide following the terms of the Privacy Policy and your choices (including settings).</p>
                <p>
                    By submitting suggestions or other feedback regarding the Services, you agree that we can use and share
                    such feedback for any purpose without compensation to you
                </p>
                <h2>
                    9. ADVERTISERS
                </h2>

                <p>
                    We allow advertisers to display their advertisements and other information in certain areas of the Services,
                    such as sidebar advertisements or banner advertisements. We simply provide the space to place such
                    advertisements, and we have no other relationship with advertisers.
                </p>
                <h2>
                    10. SERVICES MANAGEMENT
                </h2>
                <p>
                    We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2)
                    take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms,
                    including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and
                    without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically
                    feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice,
                    or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are
                    in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect
                    our rights and property and to facilitate the proper functioning of the Services.
                </p>
                <h2>11. PRIVACY POLICY</h2>
                <p>
                    We care about data privacy and security. Please review our Privacy
                    Policy: https://www.gohyper.space/privacy-policy. By using the Services, you agree to be bound by our
                    Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted
                    in India. If you access the Services from any other region of the world with laws or other requirements
                    governing personal data collection, use, or disclosure that differ from applicable laws in India, then through
                    your continued use of the Services, you are transferring your data to India, and you expressly consent to
                    have your data transferred to and processed in India.
                </p>
                <h2>
                    12. TERM AND TERMINATION
                </h2>
                <p>
                    These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING
                    ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE
                    DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES
                    (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
                    REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
                    OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR
                    REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                    DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
                    WITHOUT WARNING, IN OUR SOLE DISCRETION.

                </p>
                <p>
                    If we terminate or suspend your account for any reason, you are prohibited from registering and creating a
                    new account under your name, a fake or borrowed name, or the name of any third party, even if you may be
                    acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right
                    to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                </p>
                <h2>
                    13. MODIFICATIONS AND INTERRUPTIONS

                </h2>
                <p>
                    We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason
                    at our sole discretion without notice. However, we have no obligation to update any information on our
                    Services. We will not be liable to you or any third party for any modification, price change, suspension, or
                    discontinuance of the Services.
                </p>
                <h2>
                    14. GOVERNING LAW
                </h2>
                <p>
                    These Legal Terms shall be governed by and defined following the laws of India. Hyperspace retail labs
                    private limited and yourself irrevocably consent that the courts of India shall have exclusive jurisdiction to
                    resolve any dispute which may arise in connection with these Legal Terms.
                </p>
                <h2>
                    15. CORRECTIONS
                </h2>
                <p>
                    There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
                    including descriptions, pricing, availability, and various other information. We reserve the right to correct any
                    errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without
                    prior notice.
                </p>
                <h2>16. DISCLAIMER</h2>
                <p>
                    THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR
                    USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY
                    LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE
                    SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                    COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE
                    APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR
                    RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                    MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
                    RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3)
                    ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
                    PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
                    VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
                    SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
                    MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                    OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
                    PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES,
                    ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                    BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
                    RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                    PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE
                    THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR
                    BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                </p>
                <h2>
                    17. INDEMNIFICATION

                </h2>
                <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
                    respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
                    demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out
                    of: (1) use of the Services; (2) breach of these Legal Terms; (3) any breach of your representations and
                    warranties set forth in these Legal Terms; (4) your violation of the rights of a third party, including but not limited to
                    intellectual property rights; or (5) any overt harmful act toward any other user of the Services with whom you
                    connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the
                    exclusive defense and control of any matter for which you are required to indemnify us, and you agree to
                    cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any
                    such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>
                <h2>
                    18. USER DATA
                </h2>
                <p>We will maintain certain data that you transmit to the Services for the purpose of managing the performance
                    of the Services, as well as data relating to your use of the Services. Although we perform regular routine
                    backups of data, you are solely responsible for all data that you transmit or that relates to any activity you
                    have undertaken using the Services. You agree that we shall have no liability to you for any loss or
                    corruption of any such data, and you hereby waive any right of action against us arising from any such loss
                    or corruption of such data.
                </p>
                <h2>
                    19. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES

                </h2>
                <p>
                    Visiting the Services, sending us emails, and completing online forms constitute electronic communications.
                    You consent to receive electronic communications, and you agree that all agreements, notices, disclosures,
                    and other communications we provide to you electronically, via email and on the Services, satisfy any legal
                    requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                    SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                    NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
                    THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules,
                    ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of
                    non-electronic records, or to payments or the granting of credits by any means other than electronic means.
                </p>
                <h2>20. MISCELLANEOUS</h2>
                <p>
                    These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the
                    Services constitute the entire agreement and understanding between you and us. Our failure to exercise or
                    enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision.
                    These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights
                    and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or
                    failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of
                    these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the
                    provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of
                    any remaining provisions. There is no joint venture, partnership, employment or agency relationship created
                    between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal
                    Terms will not be construed against us by virtue of having drafted them. You hereby waive any and
                    all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the
                    parties hereto to execute these Legal Terms.
                </p>
                <h2>21. CONTACT US</h2>
                <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the
                    Services, please contact us at:</p>

                <ul>
                    <li>
                        <p>Hyperspace retail labs private limited,Dwaraka park view,Hitech City,Telangana 500081</p>
                    </li>
                    <li>
                        <p>By email: support@gohyper.space</p>
                    </li>
                    <li>
                        <p>By visiting this page on our website: <a href="https://www.gohyper.space/contact-us" rel="external nofollow noopener" target="_blank">https://www.gohyper.space/contact-us</a></p>
                    </li>
                    <li>
                        <p>By phone number: +91 96663 85000</p>
                    </li>
                </ul>
            </div>
            <Footer />

        </div>
    )
}

export default TermsandConditions
