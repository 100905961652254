import React, { useState } from 'react'
import styles from './RefundPolicy.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'

function RefundPolicy() {
    const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })
    return (
        <div className={styles.mainContainer}>
            <Navbar style={navbarstyle} />
            <div className={styles.content}>
                <h1>Refund & Cancellation Policy</h1>
               <ul className={styles.list}>
                <li>
                    <p>1.You may cancel your account at anytime by emailing support@gohyper.space</p>
                </li>
                <li>
                    <p>2.Once your account is cancelled all of your Content will be immediately deleted from our end. Since deletion of all data is final please be sure that you do in fact want to cancel your account before doing so.</p>
                </li>
                <li>
                    <p>3.<strong>Platform Subscription: </strong>If you cancel the Service in the middle of the month, you will receive one final invoice via email. Once that invoice has been paid you will not be charged again.</p>
                </li>
                <li>
                    <p>4.<strong>Fraud:</strong> Without limiting any other remedies, hyperspace. may suspend or terminate your account if we suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise) have engaged in fraudulent activity in connection with the Site.</p>
                </li>
               </ul>
               <p><strong>Note: </strong>No refunds are offered on pre-paid orders and on subscriptions even if a plan is canceled mid-month.</p>
                
                
                
                
            </div>
            <Footer/>

        </div>
    )
}

export default RefundPolicy
