import React, { useRef, useEffect, useState } from 'react'
import styles from './Distribution.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';
import { useInView } from 'framer-motion'
import { Link } from 'react-router-dom';

function Distribution() {

  const bannerRef = useRef(null);
  const whiteRef = useRef(null);
  const purpleRef = useRef(null);
  const pinkRef = useRef(null);
  const blackRef = useRef(null);

  const bannerInView = useInView(bannerRef, { once: false });
  const whiteInView = useInView(whiteRef, { once: false });
  const purpleInView = useInView(purpleRef, { once: false });
  const pinkInView = useInView(pinkRef, { once: false });
  const blackInView = useInView(blackRef, { once: false });

  const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })

  useEffect(() => {
    if (bannerInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'relative' });
    }

    if (whiteInView) {
      document.body.style.backgroundColor = '#FFFFFF';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }
    if (purpleInView) {
      document.body.style.backgroundColor = '#1C1C1C';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

    if (pinkInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

    if (blackInView) {
      document.body.style.backgroundColor = '#1C1C1C'
      document.body.style.backgroundImage = "url('/assets/images/stars.png')";
      // return;
      // setNavbarStyle({ position: 'fixed' });
    }

  }, [whiteInView, purpleInView, pinkInView, blackInView]);


  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>Retail Distribution Solution | Offline & eCommerce Listing</title>
        <meta name="description" content="Get your products listed on leading eCommerce marketplaces and offline retail stores across India. We help with Omnichannel presence for your brand." />

        <meta property="og:title" content="Retail Distribution Solution | Offline & eCommerce Listing" />
        <meta property="og:description" content="Get your products listed on leading eCommerce marketplaces and offline retail stores across India. We help with Omnichannel presence for your brand." />
        <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

        <meta name="twitter:title" content="Retail Distribution Solution | Offline & eCommerce Listing" />
        <meta name="twitter:description" content="Get your products listed on leading eCommerce marketplaces and offline retail stores across India. We help with Omnichannel presence for your brand." />
        <meta property="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />
      </Helmet>
      <Navbar style={navbarstyle} />

      <div className={styles.container}>
        <img
          src="/assets/images/saas-planet.png"
          alt="planets"
          className={styles.planet33}
        />
        <img
          src="/assets/images/planet3.png"
          alt="planets"
          className={styles.planet34}
          style={{ position: 'absolute', right: '5%', top: '13%' }}
        />
        <img
          src="/assets/images/blue-planet1.png"
          alt="planets"
          className={styles.planet35}
        />
        <img
          src="/assets/images/saas-planet2.png"
          alt="planets"
          className={styles.planet36}
        />
        {pinkInView ? null : <img
          src="/assets/images/blue-planet2.png"
          alt="planets"
          className={styles.planet37}
        />}
        {pinkInView && <img
          src="/assets/images/logistic-planet1.png"
          alt="planets"
          className={styles.planet38}
        />}
        <img
          src="/assets/images/logistic-planet2.png"
          alt="planets"
          className={styles.planet39}
        />
        <img
          src="/assets/images/red-planet5.png"
          alt="planets"
          className={styles.planet40}
        />
        <img
          src="/assets/images/logistic-planet2.png"
          alt="planets"
          className={styles.planet41}
        />
        <img
          src="/assets/images/black-planet2.png"
          alt="planets"
          className={styles.planet42}
        />
        <img
          src="/assets/images/black-planet2.png"
          alt="planets"
          className={styles.planet43}
        />
        <div className={styles.content}>
          <div className={styles.contentImgContainer} ref={bannerRef}>
            <h1>Get your products listed<br />
              <span className={styles.retailIsHere}>where your customers are!</span>
            </h1>
            <p>
              Don't limit yourself to just D2C websites and marketplaces; modern customers search for your products across various channels, including Q-Commerce, supermarkets, and kiranas.
            </p>
            <p>From getting your products listed to  ensure they are scaling;<span style={{ fontFamily: 'Poopins-Italic' }}> We take care of everything!</span></p>
            <button className={styles.getStartedBtn}><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></button>
          </div>
        </div>
        <div className={styles.heading} style={{ backgroundColor: '#1C1C1C' }}>
          <h1 style={{ color: '#FFFFFF' }}>Build dominance on<br /><span className={styles.retailIsHere}>Marketplaces.</span></h1>
          {/* <h1>
              <span style={{ color: 'var(--purple-text)' }}>All-In-One</span> Marketplace <br /> Managment Platform <br />
            </h1> */}
          <p style={{ marginTop: '20px', fontFamily: 'Poopins-Regular', color: '#FFFFFF' }}>With proven expertise in building brands in multiple marketplaces, we can help drive SCALE for your brand.</p>
          <p style={{ marginTop: '20px', fontFamily: 'Poopins-Regular', color: '#FFFFFF' }}>Why rely on just one sales channel, when you can have more? 😊</p>
        </div>
        <div className={styles.main}>
          <div ref={purpleRef} className={styles.reachCustomers}>
            {/* <div className={styles.marketplaceAdvertising}>
              <div>
                <h2>Marketplace Advertising</h2>
                <p style={{ marginTop: '15px' }}>From Amazon & flipkart ads to BigBasket or instamart sponsorships, we got you covered. Manage and track all your upcoming campaigns and promotions.</p>
              </div>
              <img alt='horn-icon' src='/assets/images/horn-icon.png' />
            </div> */}

            <div className={styles.column} style={{ marginBottom: '20px' }}>
              <div className={styles.reachCustomersItem} style={{ backgroundColor: '#8F3CF8' }}>
                <h2>Listing Optimization</h2>
                <p>Manage all your live product listings across multiple marketplaces from one dashboard.</p>
                <img alt='check-icon' src='/assets/images/listing-icon.png' />
              </div>
              <div className={styles.reachCustomersItem} style={{ backgroundColor: '#FE655D' }}>
                <h2>Competitor Analysis</h2>
                <p>Keep an eye on your competition! Learn how to stay ahead in the game and drive results.</p>
                <aside><img alt='check-icon' src='/assets/images/analysis-icon.png' /></aside>
              </div>
            </div>
            <div className={styles.column}>
              <div className={styles.reachCustomersItem} style={{ backgroundColor: '#FE655D' }}>
                <h2>Analytics & Reporting</h2>
                <p>Optimize your business with our advanced Ecommerce Analytics & Reporting. Gain valuable insights into your sales and customer trends to drive growth and success!</p>
                <img alt='check-icon' src='/assets/images/analytics-icon.png' />
              </div>
              <div className={styles.reachCustomersItem} style={{ backgroundColor: '#8F3CF8' }}>
                <h2>Order Management</h2>
                <p>Manage & track all your orders across multiple channels using our unified dashboard and ensure the fulfillment within SLAs.<br /><br /></p>
                <aside><img alt='check-icon' src='/assets/images/order-management.png' /></aside>
              </div>
            </div>
          </div>


          <div style={{
            width: '1020px',
            maxWidth: '100%',
            backgroundColor: '#0D0D0D'
          }} className={styles.heading}>
            <h1 style={{ color: '#FFFFFF' }}>
              <span className={styles.retailIsHere}>Team of eCommerce<br /> Experts </span> for all Your marketplace needs.
            </h1>
            <p style={{ marginTop: '20px', color: '#FFFFFF' }}>Our team of ecom experts are here 24*7 for all your operations and growth needs. From listings & product catalogs to tech & design, We got you covered!</p>
          </div>
          <div ref={pinkRef} className={styles.getControl}>


            <div className={styles.column} >
              <div className={styles.getControlItems}>
                <img alt='courier-icon' src='/assets/images/account-manager.png' />
                <h2>Account Manager</h2>
                <ul >
                  <li>Manage your marketplaces</li>
                  <li>Execute Campaigns and plan for future ones</li>
                  <li>Monitor customer feedback</li>
                </ul>
              </div>

              <div className={styles.getControlItems}>
                <img alt='courier-icon' src='/assets/images/tech-team.png' />
                <h2>Tech Team</h2>
                <ul>
                  <li>Maintenance & monitoring of marketplaces</li>
                  <li>Website Development</li>
                  <li>Custom Integrations</li>
                </ul>
              </div>

              <div className={styles.getControlItems}>
                <img alt='courier-icon' src='/assets/images/product-design.png' />
                <h2>Product Design</h2>
                <ul>
                  <li>Tailor-made listing to each marketplace</li>
                  <li>Design brochures, banners & other campaign creatives</li>
                  <li>Seasonal store makeup</li>
                  <li>Product Descriptions</li>
                </ul>
              </div>
            </div>


            <div className={styles.socialMedia} >
              <div>
                <h2>Industry-Leading Partnerships To Scale Your Offline Retail</h2>
                <p>We partnered with all the major POS companies to get you real-time data & insights about your product.<br /> With hyperspace building a retail network for your products is no longer tough!</p>
              </div>
              <img alt='social-media' src='/assets/images/distribution.png' />
            </div>

            {/* <div className={styles.column}>
              <div className={styles.courierItems}>
                <div>
                  <h2>Plug ‘n’ Play Distribution Solution.</h2>
                  <span>Manage all your marketplaces and offline distribution channels.<br/>With hyperspace, reach wherever your customers are.</span>
                </div>
                <img alt='ndr-icon' src='/assets/images/plug-n-play.png' />
              </div>
              <div className={styles.courierItems}>
                <img alt='devliveries-icon' src='/assets/images/logistics-icon.png' />
                <div>
                  <h2 style={{ marginTop: '30px' }}>Optimized Logistics Solution.</h2>
                  <span>No more multiple courier service accounts. Use hyperspace to deliver across 28000+ pin codes.<br/>Identity ‘authentic’ orders and fulfill them on-the-go.</span>
                </div>
              </div>
            </div> */}


            <h1 className={styles.goingRetailHeading}>
              Going Retail isn’t a choice’ ;<br /><span className={styles.retailIsHere} >It's a must!</span>
            </h1>

            <div className={styles.column} >
              <div className={styles.getControlItems}>
                <img alt='scaling-icon' src='/assets/images/scaling-icon.png' />
                <h2>Scaling</h2>
                <p style={{ marginTop: '15px' }}>Understand each region down to the kirana store level and plan marketing activities that ensure consistent sales.</p>
              </div>

              <div className={styles.getControlItems}>
                <img alt='network-icon' src='/assets/images/network-icon.png' />
                <h2>Network</h2>
                <p style={{ marginTop: '15px' }}>Network of GT & Modern Trade stores across the country ensuring your products are everywhere.</p>
              </div>

              <div className={styles.getControlItems}>
                <img alt='capital-icon' src='/assets/images/capital-icon.png' />
                <h2>Capital</h2>
                <p style={{ marginTop: '15px' }}>Connect with the leading VCs, Banks and NVFCs, while helping you reach goals.</p>
              </div>
            </div>


          </div>

          <div className={styles.boostingPostHeading}>
            <h1>
              Bring Online & Offline Retail Together & Ensure an
              <span style={{ color: 'var(--gray-text)' }}> Omni-Channel Brand Presence.</span>
            </h1>
          </div>
          <div ref={blackRef} className={styles.leadingCouriers}>

            <div className={styles.boostingPostSubheading}>
              <h1>Want to see your Product in every household?</h1>
              <p>Ensure an Omni - Channel Brand Presence</p>
              <button className={styles.getStartedBtn}><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></button>
            </div>

          </div>


        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Distribution;
