// import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation, } from 'react-router-dom';
import Landing from './Screens/Landing/Landing';
import Waitlist from './Screens/Waitlist/Waitlist';
import AboutUS from './Screens/AboutUs/AboutUS';
// import Tracking from './Screens/Tracking/Tracking';
import Tracking from './Screens/Tracking/Tracking';
import Logistics from './Screens/Logistics/Logistics';
import Sass from './Screens/Sass/Sass';
import Marketing from './Screens/Marketing/Marketing';
import Distribution from './Screens/Distribution/Distribution';
import PrivacyPolicy from './Screens/PrivacyPolicy/PrivacyPolicy';
import TermsandConditions from './Screens/TermsandConditions/TermsandConditions';
// import { useEffect } from 'react';
import { useEffect } from 'react';
import RefundPolicy from './Screens/RefundPolicy/RefundPolicy';





function App() {

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
      //
    }, [pathname]);

    return null;
  };


  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/contact-us' element={<Waitlist />} />
        <Route path='/about-us' element={<AboutUS />} />
        <Route path='/track/:id' element={<Tracking />} />
        <Route path='/logistics' element={<Logistics />} />
        <Route path='/platform' element={<Sass />} />
        <Route path='/marketing' element={<Marketing />} />
        <Route path='/distribution' element={<Distribution />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-service' element={<TermsandConditions />} />
        <Route path='/refund-cancellation-policy' element={<RefundPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
