import React, { useState } from 'react'
import styles from './Navbar.module.scss'
import { Link } from 'react-router-dom'


function Navbar({ style = {} }: any) {
    const [active, setActive] = useState<any>(false);
    const [mobileDropDown, setMobileDropDown] = useState<any>(false);

    const hadleDropDown = () => {
        setMobileDropDown(!mobileDropDown)
    }

    return (
        <div className={styles.navContainer} style={style}>
            <div className={styles.nav}>
                <Link to="/" className={styles.logoContainer}>
                    <img src='../assets/images/hyperspace_logo.png' alt='logo' />
                </Link>
                <ul className={`${styles.navItems} ${active ? styles.active : ''}`}>
                    <li>
                        <Link to="/platform">Platform</Link>
                    </li>
                    <li>
                        <div className={styles.dropdown}>
                            <span>Solutions <img src='../assets/images/donwarrow.svg' /> </span>
                            <ul className={styles.dropdownContent}>
                                <li><Link to="/marketing">Discovery </Link></li>
                                <li><Link to="/distribution">Distribution</Link></li>
                                <li><Link to="/logistics">Delivery</Link></li>
                                {/* <li><Link to="/saas">SaaS</Link></li> */}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                        <Link to="/contact-us">Contact Us</Link>
                    </li>
                </ul>
                <ul className={`${styles.mobilenavItems} ${active ? styles.active : ''}`}>
                    <li>
                        <Link to="/platform">Platform</Link>
                    </li>
                    <li>
                        {/* <Link to="/contact-us">Solutions</Link> */}
                        <div className={styles.dropdown}>
                            <span onClick={() => hadleDropDown()}>Solutions <img src='../assets/images/donwarrow.svg' /> </span>
                            <ul className={`${styles.dropDowmActive} ${mobileDropDown ? styles.mobileActive : ""}`}>
                                <li><Link to="/marketing">Discovery </Link></li>
                                <li><Link to="/distribution">Distribution</Link></li>
                                <li><Link to="/logistics">Delivery</Link></li>
                                {/* <li><Link to="/saas">SaaS</Link></li> */}
                            </ul>
                        </div>
                    </li>
                    <li>
                        <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                        <Link to="/contact-us">Contact Us</Link>
                    </li>
                </ul>
                <div className={`${styles.hamburger} ${active ? styles.active : ''}`} onClick={() => setActive(!active)}>
                    <div className={styles.line}></div>
                    <div className={styles.line}></div>
                    <div className={styles.line}></div>
                </div>
            </div>
        </div>
    )
}

export default Navbar
