import React, { useRef, useEffect, useState } from 'react'
import styles from './Marketing.module.scss'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Helmet } from 'react-helmet-async';
import { useInView } from 'framer-motion'
import { Link } from 'react-router-dom';

function Marketing() {

  const bannerRef = useRef(null);
  const whiteRef = useRef(null);
  const purpleRef = useRef(null);
  const pinkRef = useRef(null);
  const blackRef = useRef(null);

  const bannerInView = useInView(bannerRef, { once: false });
  const whiteInView = useInView(whiteRef, { once: false });
  const purpleInView = useInView(purpleRef, { once: false });
  const pinkInView = useInView(pinkRef, { once: false });
  const blackInView = useInView(blackRef, { once: false });

  const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })

  useEffect(() => {
    if (bannerInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'relative' });
    }

    if (whiteInView) {
      document.body.style.backgroundColor = '#FFFFFF';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }
    if (purpleInView) {
      document.body.style.backgroundColor = '#1C1C1C';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

    if (pinkInView) {
      document.body.style.backgroundColor = '#0D0D0D';
      document.body.style.backgroundImage = "none";
      // setNavbarStyle({ position: 'fixed' });
    }

    if (blackInView) {
      document.body.style.backgroundColor = '#1C1C1C'
      document.body.style.backgroundImage = "url('/assets/images/stars.png')";
      // return;
      // setNavbarStyle({ position: 'fixed' });
    }

  }, [whiteInView, purpleInView, pinkInView, blackInView]);




  const phoneRef = useRef(null);
  const emailRef = useRef(null);


  const handleSubmit = (e: any) => {
    e.preventDefault()
  }

  return (
    <div className={styles.mainContainer}>
      <Helmet>
        <title>Marketing Solutions | D2C Perfromance Marketing</title>
        <meta name="description" content="Marketing solutions customized to meet your business goals with a core focus on brand-building & Performance Marketing." />

        <meta property="og:title" content="Marketing Solutions | D2C Perfromance Marketing" />
        <meta property="og:description" content="Marketing solutions customized to meet your business goals with a core focus on brand-building & Performance Marketing." />
        <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

        <meta name="twitter:title" content="Marketing Solutions | D2C Perfromance Marketing" />
        <meta name="twitter:description" content="Marketing solutions customized to meet your business goals with a core focus on brand-building & Performance Marketing." />
        <meta property="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

        

      </Helmet>
      <Navbar style={navbarstyle} />
      <div className={styles.container}>
        <img
          src="/assets/images/saas-planet.png"
          alt="planets"
          className={styles.planet24}
        />
        <img
          src="/assets/images/planet3.png"
          alt="planets"
          className={styles.planet25}
        />
        <img
          src="/assets/images/blue-planet3.png"
          alt="planets"
          className={styles.planet26}
        />
        <img
          src="/assets/images/blue-planet1.png"
          alt="planets"
          className={styles.planet27}
        />
        <img
          src="/assets/images/logistic-planet1.png"
          alt="planets"
          className={styles.planet28}
        />
        <img
          src="/assets/images/logistic-planet2.png"
          alt="planets"
          className={styles.planet29}
        />
        <img
          src="/assets/images/marketing-planet.png"
          alt="planets"
          className={styles.planet30}
        />
        <img
          src="/assets/images/black-planet2.png"
          alt="planets"
          className={styles.planet31}
        />
        <img
          src="/assets/images/black-planet2.png"
          alt="planets"
          className={styles.planet32}
        />
        <div className={styles.content}>
          <div className={styles.contentImgContainer} ref={bannerRef}>
            <h1>Make Your Brand<br />
              <span className={styles.retailIsHere}>Discover-able!</span>
            </h1>

            <p>
              Let leading growth marketers and creative experts work for you while you focus on other important things.<br />

            </p>
            <p style={{ fontFamily: 'Poopins-Italic' }}> It's growth on steroids, Quite Literally!</p>
            <button><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></button>
          </div>
        </div>
        <div className={styles.heading} style={{ backgroundColor: '#1C1C1C' }}>
          <h1 style={{ color: '#FFFFFF' }}><span className={styles.retailIsHere}>Create Content </span>that’s<br />worth Marketing</h1>
          {/* <h1>
            <span style={{ color: 'var(--purple-text)' }}> </span> 
          </h1> */}
          <p style={{ marginTop: '30px', color: '#FFFFFF' }}>Build a vibrant presence across all social media platforms and captivate the right audience.<br />
            Don't just rely on performance marketing, build strong brands that work as assets.</p>
        </div>
        <div className={styles.main}>
          <div ref={purpleRef} className={styles.reachCustomers}>
            <div className={styles.column}>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>We Listen</h2>
                <span>Our team develops an intimate understanding of your consumers and what their interests are.</span>
              </div>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>We Plan</h2>
                <span>Our experts will work closely with you to understand your objectives & help you build a compelling brand story</span>
              </div>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>We Execute</h2>
                <span>We create unforgettable brand stories to captivate new & existing customers for maximum reach and engagement.</span>
              </div>
              <div className={styles.reachCustomersItem} >
                <img alt='check-icon' src='/assets/images/check-icon.png' />
                <h2>We Optimise</h2>
                <span>We continually track results of campaigns and give you insights in real-time.</span>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '100px', marginBottom: '50px' }}>
            <div className={styles.heading} style={{
              width: '1020px',
              maxWidth: '100%',
              backgroundColor: '#0D0D0D',
              // marginTop:'70px'
            }}>
              <h1 style={{ marginBottom: '20px', color: '#FFFFFF' }}>
                <span className={styles.retailIsHere} >Performance Marketing</span> <br /> that works for you!
              </h1>
              <p style={{ marginBottom: '20px', color: '#FFFFFF' }}>With experts on team who has worked with some of the leading brands in india, We get things done in an ideal way!</p>
              <p style={{ color: '#FFFFFF' }}>The important part, performance marketing isn’t just spending money on ads and dreaming for sales. We help you build a long-term strategy to acquire new customers everyday with an 360 degree marketing solutions.</p>
            </div>
          </div>
          <div ref={pinkRef} className={styles.getControl}>
            <div className={styles.socialMedia} >
              <div>
                <h2 style={{ color: "#FFFFFF" }}>Social Media</h2>
                <p style={{ color: "#FFFFFF" }}>Target all social platforms to spread brand awareness or promote particular products. Increase engagement and see your brand grow!</p>
              </div>
              <img alt='social-media' src='/assets/images/social-media-icon.png' />
            </div>

            <div className={styles.column}>
              <div className={styles.courierItems}>
                <img style={{ position: 'absolute', right: '20px', top: '0px', maxWidth: '80%' }} alt='pickup-icon' src='/assets/images/sem-icon.png' />
                <div style={{ position: 'absolute', bottom: '20px' }}>
                  <h2>SEM</h2>
                  <span>Rank higher than your competition in search results & target users via paid advertising to your website</span>
                </div>
              </div>
              <div className={styles.courierItems}>
                <img style={{ position: 'absolute', right: '20px', maxWidth: '80%' }} alt='ndr-icon' src='/assets/images/seo-icon.png' />
                <div style={{ position: 'absolute', bottom: '20px' }}>
                  <h2>SEO</h2>
                  <span>Discover which keywords work well for your product and drive organic traffic to your website.</span>
                </div>
              </div>
              <div className={styles.courierItems}>
                <img style={{ position: 'absolute', top: '30px', right: '10px', maxWidth: '80%' }} alt='devliveries-icon' src='/assets/images/digital-ads-icon.png' />
                <div style={{ position: 'absolute', bottom: '30px', right: '20px', left: '20px' }}>
                  <h2 style={{ maxWidth: '60%' }}>Paid & Digital Ads</h2>
                  <span>We will run paid and organic digital marketing campaigns for the brand. We ensure that Every rupee you spend on Ads has an ROI.</span>
                </div>
              </div>
              <div className={styles.courierItems}>
                <img style={{ position: 'absolute', top: '10px', right: '20px', maxWidth: '80%' }} alt='devliveries-icon' src='/assets/images/Isolation_Mode.png' />
                <div style={{ position: 'absolute', bottom: '30px', right: '20px', left: '20px' }}>
                  <h2 style={{ maxWidth: '80%' }}>Programmatic Buying</h2>
                  <span>Put your brand in front of the right audience at the right time with automated sales and purchases of digital advertising space.</span>
                </div>
              </div>
              <div className={styles.courierItems}>
                <img style={{ position: 'absolute', top: '20px', right: '40px' }} alt='ndr-icon' src='/assets/images/design-strategy-icon.png' />
                <div style={{ position: 'absolute', bottom: '35px' }}>
                  <h2 style={{ maxWidth: '60%' }}>Design Strategy</h2>
                  <span>Using marketplace insights, we can develop design strategies that better align with your customers' needs.</span>
                </div>
              </div>
              <div className={styles.courierItems}>
                <img style={{ position: 'absolute', top: '20px', right: '20px' }} alt='devliveries-icon' src='/assets/images/affilate-network-icon.png' />
                <div style={{ position: 'absolute', bottom: '50px' }}>
                  <h2 style={{ maxWidth: '60%' }}>Affiliate Networks</h2>
                  <span>Connect with third party publishers to generate traffic or leads for your products.</span>
                </div>
              </div>



            </div>
            <div className={styles.marketplaceAdvertising}>
              <div>
                <h2>Marketplace Advertising</h2>
                <p style={{ marginTop: '15px' }}>From Amazon & flipkart ads to BigBasket or instamart sponsorships, we got you covered. Manage and track all your upcoming campaigns and promotions.</p>
              </div>
              <img alt='horn-icon' src='/assets/images/horn-icon.png' />
            </div>

          </div>




          <div className={styles.boostingPostHeading}>
            <h1 style={{ color: '#FFFFFF' }}>
              Boosting posts isn’t what we do, We go much deeper.
            </h1>

            <p style={{ color: '#FFFFFF' }}>Our innovative 'Account-based Commerce' approach leverages our logistics and distribution expertise for sustainable Omni-channel growth.  </p>
            <p style={{ color: '#FFFFFF' }}>We prioritize holistic marketing, going beyond traditional lead generation to maximize customer value. </p>

          </div>
          <div className={styles.leadingCouriers}>

            <div className={styles.boostingPostSubheading}>
              <h1>Ready to Boost Your Growth Journey?</h1>
              <p>Accelerate your brand’s growth with omni - channel <br /> marketing strategy</p>
              <button ref={blackRef} className={styles.getStartedBtn}><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></button>
            </div>

          </div>


        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Marketing
