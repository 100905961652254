import React, { useState } from 'react'
import styles from './Waitlist.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import Checkbox from '@mui/material/Checkbox';
import Footer from '../../Components/Footer/Footer';
import { useFormik } from 'formik';
import * as yup from "yup";
import axios from 'axios';
import { Helmet } from 'react-helmet-async';


const initialValues = {
    name: '',
    mobile: '',
    email: '',
    message: '',
    agree: false
}

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    mobile: yup
        .string()
        .required()
        // .matches(/[1-9][0-9]{9}/, "Please enter valid mobile number")
        .min(10),
    email: yup.string().email().required("Email is Required"),
    // file: yup.mixed().required('File is required'),
    message: yup.string().required(),
    // agree: yup.string().required()
    agree: yup.boolean().oneOf([true], 'You must agree to the Terms and Conditions').required(),




})

function Waitlist() {
    const {
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue

    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            console.log(values)
            var apiKey = "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";
            var senderEmail = values.email;

            


            var data = {
                sender: { email: senderEmail },
                to: [{ email: "vinay@gohyper.space" }, { email: "kalyan.utti@gohyper.space" }, { email: "ved@gohyper.space" },{ email: "mission@gohyper.space" }],
                // cc: [{email: 'uttikalyan123@gmail.com'}, {email: 'bharshavardhini16@gmail.com'}],
                subject: "go hyperspace",
                templateId: 1,
                params: {

                    name: values.name,
                    email: values.email,
                    phone: values.mobile,
                    message: values.message



                }
            };

            const config: any = {
                method: "POST",
                url: 'https://api.sendinblue.com/v3/smtp/email',
                headers: {
                    "Content-Type": "application/json",
                    "api-key": apiKey
                },
                data: JSON.stringify(data)
            }
            axios(config)
                .then(res => {
                    console.log("Email send ")

                    if (res.status === 201) {
                        var contactData = {
                            email: values.email,
                            // listIds: [4],
                            updateEnabled: true,
                            attributes: {
                                SOURCE: "ContactUs",
                                FIRSTNAME: values.name,
                                SMS: `+91${values.mobile}`
                            }
                        };
                        var config1: any = {
                            method: "POST",
                            url: 'https://api.brevo.com/v3/contacts',
                            headers: {
                                "Content-Type": "application/json",
                                "api-key": apiKey
                            },
                            data: JSON.stringify(contactData)
                        }

                        axios(config1)
                            .then(res => {
                                console.log("data added sucessfully")
                                setEmailsent(true)
                            })
                            .catch(err => {
                                console.log('something went wrong')
                            })


                    }
                })
                .catch(err => {
                    console.log(err)
                })

            resetForm()
            setFieldValue("agree", false)


        }
    })
    const [emailsent, setEmailsent] = useState<any>(false);
    const pageTitle = 'Join the Waitlist | hyperspace';
    const pageDescription = 'Get on the waitlist to be one of the firsts to experience the hyperspace world.';
    return (

        <div className={styles.mainContainer}>
            <Helmet>
                <title>Reach Us | hyperspace.</title>
                <meta name="description" content="We love to help you, just reach us anytime..we are available always!" />

                <meta property="og:title" content="Reach Us | hyperspace." />
                <meta property="og:description" content="We love to help you, just reach us anytime..we are available always!" />
                <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

                <meta name="twitter:title" content="Reach Us | hyperspace." />
                <meta name="twitter:description" content="We love to help you, just reach us anytime..we are available always!" />
                <meta property="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />
            </Helmet>
            <Navbar />
            <div className={styles.container}>
                {/* <img
                    src="https://assets.gohyper.space/web-landing/images/Group+5923.png"
                    alt="planets"
                    className={styles.planet1}
                />
                <img
                    src="https://assets.gohyper.space/web-landing/images/Group+5924.png"
                    alt="planets"
                    className={styles.planet2}
                /> */}
                <div className={styles.contentContainer}>
                    <div className={styles.content}>
                        <div className={styles.waitlistContent}>
                            <h1>Onboard NOW!!</h1>
                            <p className={styles.description}>
                                Brace yourself! You are going to onboard a spaceship that will take your D2C / ecommerce business to the next level.
                            </p>
                            <p className={styles.description}>
                                Schedule a call back with our team &
                                SCALE your business.
                            </p>

                            {/* <div className={styles.imgContainer}>
                                <img
                                    src="https://assets.gohyper.space/web-landing/images/Group+5928.png"
                                    alt="planets"
                                    className={styles.planet3}
                                />
                            </div> */}
                        </div>
                        <form className={styles.formContainer} onSubmit={handleSubmit}>
                            <div className={styles.inputContainer}>
                                <div>
                                    <label htmlFor='name'>Name*</label>
                                    <input
                                        type='text'
                                        id='name'
                                        className={styles.input}
                                        onChange={handleChange("name")}
                                        onBlur={handleBlur("name")}
                                        value={values.name}
                                        placeholder='Enter...'
                                    />
                                    {touched.name && errors.name ? (
                                        <p className={styles.errorMsg}>Name is required</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.inputContainer}>
                                <div>
                                    <label htmlFor='email'>Email Id*</label>
                                    <input
                                        type='email'
                                        id='email'
                                        className={styles.input}
                                        onChange={handleChange("email")}
                                        onBlur={handleBlur("email")}
                                        value={values.email}
                                        placeholder='Enter...'
                                    />
                                    {touched.email && errors.email ? (
                                        <p className={styles.errorMsg}>Email is required</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.inputContainer}>
                                <div>
                                    <label htmlFor='mobile'>Mobile Number*</label>
                                    <input
                                        type='tel'
                                        id='mobile'
                                        className={styles.input}
                                        placeholder='Enter...'
                                        onChange={(text: any) => {
                                            const regex =
                                                /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;
                                            const textValue = text.target.value
                                            if (regex.test(textValue.toString()) || textValue === "") {
                                                setFieldValue("mobile", textValue);
                                            }
                                            console.log(textValue)
                                            console.log(values.mobile)
                                        }}
                                        maxLength={10}
                                        onBlur={handleBlur('mobile')}
                                        value={values.mobile}
                                    />
                                    {touched.mobile && errors.mobile ? (
                                        <p className={styles.errorMsg}>Mobile number is required</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.inputContainer}>
                                <div>
                                    <label htmlFor='message'>Message*</label>
                                    <textarea
                                        id='message'
                                        className={styles.input}
                                        rows={1}
                                        cols={45}
                                        onChange={handleChange("message")}
                                        onBlur={handleBlur("message")}
                                        value={values.message}
                                        placeholder='Enter...'
                                    />
                                    {touched.message && errors.message ? (
                                        <p className={styles.errorMsg}>Message is required</p>
                                    ) : null}
                                </div>
                            </div>
                            <div className={styles.checkboxContainer}>
                                <Checkbox
                                    sx={{
                                        color: '#EBEBEB'
                                        ,
                                        '&.Mui-checked': {
                                            color: ' #EBEBEB',
                                        },
                                    }}
                                    onChange={(event) => setFieldValue("agree", event.target.checked)}
                                    onBlur={handleBlur("agree")}
                                    checked={values.agree}
                                />
                                <label id="checkbox" className={styles.agreeLabel}>I agree to the above mentioned details are correct.</label>
                            </div>
                            {touched.agree && errors.agree ? (
                                <p className={styles.errorMsg}>accept the conditions</p>
                            ) : null}
                            {emailsent ? <p className={styles.success}>Thanks for Your Interest</p> : null}
                            <div className={styles.button}>
                                <button type='submit' className={styles.btn} >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <div className={styles.CardContainer}>
                    <div className={styles.Card}>
                        <p className={styles.cardHeading}><span className={styles.voilet}>Interested</span> in
                            Our solution
                            or services?</p>
                        <p className={styles.description}>Schedule a call back from our experts.</p>
                        <div>
                            <button type='button' className={styles.calltoactionbtn}>
                                Contact Us
                            </button>


                        </div>

                    </div>
                    <div className={styles.Card}>
                        <p className={styles.cardHeading}><span className={styles.voilet}>Already</span> one of Our Brand-partners?</p>
                        <p className={styles.description}>Our support team is happy to help.</p>
                        <div>
                            <button type='button' className={styles.calltoactionbtn}>
                                Reach Support
                            </button>


                        </div>

                    </div>
                    <div className={styles.Card}>
                        <p className={styles.cardHeading}><span className={styles.voilet}>Got</span> another Question? Shoot.</p>
                        <p className={styles.description}>Feel free to ask us anything about brands.</p>
                        <div>
                            <button type='button' className={styles.calltoactionbtn}>
                                Contact Us
                            </button>


                        </div>

                    </div>

                </div>
                <img
                    src="https://assets.gohyper.space/web-landing/images/Group+5926.png"
                    alt="planets"
                    className={styles.planet4}
                /> */}
            </div>
            <Footer />
        </div>
    )
}

export default Waitlist
