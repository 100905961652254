import React from 'react'
import styles from './Footer.module.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className={styles.footerContainer}>
            <div className={styles.footer}>
                <span>hyperspace.</span>
                <div className={styles.socialMedia}>
                    <a href="https://www.linkedin.com/company/gohyperspace">
                        <img
                            src="https://assets.gohyper.space/web-landing/images/Icon+awesome-linkedin-in.png"
                            alt="linkedIn"
                        />
                    </a>
                    <a href="https://twitter.com/gohyperspace">
                        <img
                            src="https://assets.gohyper.space/web-landing/images/Icon+awesome-twitter.png"
                            alt="twitter"
                        />
                    </a>
                    <a href="https://www.facebook.com/gohyperspace">
                        <img
                            src="https://assets.gohyper.space/web-landing/images/Icon+awesome-facebook-f.png"
                            alt="facebook"
                        />
                    </a>
                </div>
            </div>
            <hr />
            <div className={styles.about}>

                <span>©hyperspace 2024. All Rights Reserved</span>

                <Link to="/terms-of-service"><span>Terms of Service</span></Link>
               
                <Link to="/privacy-policy"><span>Privacy Policy</span></Link>
                <Link to="/refund-cancellation-policy"><span>Refund Policy</span></Link>
            </div>
        </div>
    )
}

export default Footer
