import React, { useEffect, useRef, useState } from 'react'
import styles from './Sass.module.scss'
import { Helmet } from 'react-helmet-async'
import Navbar from '../../Components/Navbar/Navbar'
import { Link } from 'react-router-dom'
import { useInView } from 'framer-motion'
import Footer from '../../Components/Footer/Footer'




function Sass() {


    const bannerRef = useRef(null);
    const whiteRef = useRef(null);
    const purpleRef = useRef(null);
    const pinkRef = useRef(null);
    const blackRef = useRef(null);

    const bannerInView = useInView(bannerRef, { once: false });
    const whiteInView = useInView(whiteRef, { once: false });
    const purpleInView = useInView(purpleRef, { once: false });
    const pinkInView = useInView(pinkRef, { once: false });
    const blackInView = useInView(blackRef, { once: false });

    const [navbarstyle, setNavbarStyle] = useState({ position: 'fixed' })

    useEffect(() => {
        if (bannerInView) {
            document.body.style.backgroundColor = '#0D0D0D';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ position: 'relative' });
        }

        if (whiteInView) {
            document.body.style.backgroundColor = '#1C1C1C';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ position: 'fixed' });
        }
        if (purpleInView) {
            document.body.style.backgroundColor = '#E6D4FD';
            document.body.style.backgroundImage = "none";
            return;
        }

        if (pinkInView) {
            document.body.style.backgroundColor = '#0D0D0D';
            document.body.style.backgroundImage = "none";
            // setNavbarStyle({ position: 'fixed' });
            // return;
        }

        if (blackInView) {
            document.body.style.backgroundColor = '#1C1C1C'
            document.body.style.backgroundImage = "url('/assets/images/stars.png')";
            // return;
            // setNavbarStyle({ position: 'fixed' });
        }

    }, [whiteInView, purpleInView, pinkInView, blackInView]);


    const phoneRef = useRef(null);
    const emailRef = useRef(null);





    return (
        <div className={styles.mainContainer}>
            <Helmet>
                <title>Omnichannel Retail Intelligence Platform | Courier Aggregation</title>

                <meta name="description" content="Track your entire business operations using a single dashboard, from managing order across multiple channels, shipping with 10+ courier partners and smart marketing analytics along with AI insights." />

                <meta property="og:title" content="Omnichannel Retail Intelligence Platform | Courier Aggregation" />
                <meta property="og:description" content="Track your entire business operations using a single dashboard, from managing order across multiple channels, shipping with 10+ courier partners and smart marketing analytics along with AI insights." />
                <meta property="og:image" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />


                <meta name="twitter:description" content="Track your entire business operations using a single dashboard, from managing order across multiple channels, shipping with 10+ courier partners and smart marketing analytics along with AI insights." />
                <meta name="twitter:title" content="Omnichannel Retail Intelligence Platform | Courier Aggregation" />
                <meta property="twitter:card" content="https://assets.gohyper.space/web-landing/images/hyperspace_logo_png.png" />

            </Helmet>
            <Navbar style={navbarstyle} />

            <div className={styles.container} ref={bannerRef}>
                <div className={styles.content}>
                    <img
                        src="/assets/images/saas-planet.png"
                        alt="planets"
                        className={styles.planet44}
                    />
                    <img
                        src="/assets/images/planet3.png"
                        alt="planets"
                        className={styles.planet45}
                    />
                    <div className={styles.contentImgContainer}>
                        {/* <h1></h1> */}
                        <h1>Effortlessly manage <br /> your <span className={styles.retailIsHere}>eCommerce <br />Business</span><span className={styles.italic}> with our Unified Dashboard</span></h1>
                        <div>
                            <p>Connect & Manage your marketplaces, orders, inventory, shipping and marketing effortlessly- all in one platform.
                                <br /></p>
                            <p style={{ fontFamily: "Poopins-Italic", marginTop: '10px' }}>Simplify your operations and stay on top of the competition.</p>

                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <Link to="/" className={styles.getStartedBtn}><Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }}>Get Started</Link></Link>

                        </div>

                    </div>
                    <div className={styles.easilyManageDiv}>
                        <h1 style={{ color: '#FFFFFF' }}><span className={styles.retailIsHere}>Easily Manage All<br /> Your Sales Channels </span>Both Online & Offline</h1>
                        {/* <h1 ><span style={{color:'#FFFFFF'}}></span></h1> */}
                        <p style={{ color: '#FFFFFF' }}>Connect and Sync all your marketplaces, manage all orders and their fulfillment seamlessly.</p>

                    </div>
                </div>
                <div className={styles.easilyManageContainer} ref={whiteRef}>
                    <img
                        src="/assets/images/saas-planet1.png"
                        alt="planets"
                        className={styles.planet46}
                    />
                    <img
                        src="/assets/images/planet3.png"
                        alt="planets"
                        className={styles.planet47}
                    />
                    <img
                        src="/assets/images/saas-planet2.png"
                        alt="planets"
                        className={styles.planet48}
                    />
                    <img
                        src="/assets/images/blue-planet1.png"
                        alt="planets"
                        className={styles.planet49}
                    />
                    <div className={styles.noBsItems}>
                        <div className={styles.noBsItem1}>
                            <div className={styles.noBscontent}>
                                <h2>Make Data-Backed Decisions with real-time data analytics</h2>
                                <div className={styles.noBsDesc}>
                                    <p>Step into a realm where data becomes a strategic asset. Our analytics dashboard keeps you updated with real-time pre-paid and COD order reports.</p>
                                    <p>With our platform, tracking live orders, orders picked, NDR orders & delivered orders is as easy as possible.</p>
                                </div>
                            </div>
                            <img alt='icon' src='/assets/images/platform_dashboard.png' />
                        </div>
                        <div className={styles.column} >
                            <div className={styles.noBsItem2}>
                                <h2>Ship your Orders at Zero extra cost</h2>

                                <p>Your one-stop solution to ship your orders across 10+ leading couriers covering 27000+ pincodes.</p>

                                <p>Connect ALL your sales channels and ship orders on your own terms.</p>
                                <img alt='icon' src='/assets/images/sass-arrow.png' />
                            </div>
                            <div className={styles.noBsItem2} style={{ backgroundColor: '#FE655D' }}>
                                <img alt='icon' src='/assets/images/saas-objects.png' />
                                <h2>Know your Offline Sales Data</h2>
                                <p>With extensive partnerships across POS companies, market-intelligence agencies and our own on-ground team. We get you real-time data of your product sales across most of the stores. </p>
                                <p>We know how to measure & track offline sales aswell :)</p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.actionMainContainer} ref={pinkRef}>
                    <img
                        src="/assets/images/saas-planet3.png"
                        alt="planets"
                        className={styles.planet50}
                    />
                    <img
                        src="/assets/images/saas-planet4.png"
                        alt="planets"
                        className={styles.planet51}
                    />
                    <img
                        src="/assets/images/black-planet2.png"
                        alt="planets"
                        className={styles.planet52}
                    />
                    <img
                        src="/assets/images/black-planet2.png"
                        alt="planets"
                        className={styles.planet53}
                    />
                    <div className={styles.actionContainer}>
                        <h1 style={{ color: '#FFFFFF' }}><span className={styles.retailIsHere}>Get “Action” able Insights</span> with Predictive Analytics Solution</h1>
                        {/* <h1><span className={styles.highlight}></span> </h1> */}
                        <p >Make informed decisions with our data-backed algorithms. Uncover valuable insights by identifying trends and patterns in your business.</p>
                        <p>Seize opportunities, mitigate risks, and take swift, decisive action in real-time.</p>

                    </div>
                    <div className={styles.easilyManageContainer} >
                        <div className={styles.noBsItems}>
                            <div className={styles.column} >
                                <div className={styles.actionCard}>
                                    <h2>One-dashboard for all your Marketing Metrics
                                    </h2>
                                    <p>Track your sales & marketing stats across multiple platforms using a singe dashboard.
                                    </p>
                                    <p>Not just tracking, we understand ROI, CAC, ROAS, Margins etc at a much deeper level, so based on your traction we help you with intelligent predictions that you can use for making informed decisions.
                                    </p>
                                    <img alt='icon' src='/assets/images/saas-dashboard1.png' style={{ marginTop: '30px' }} />
                                </div>
                                <div className={styles.actionCard} style={{ backgroundColor: '#FE655D' }}>
                                    <img alt='icon' src='/assets/images/saas-dashboard2.png' />
                                    <h2>Be smart on Marketplaces</h2>
                                    <p>Scaling on Marketplaces is tough but its a gold mine for product sales & long-term brand building.</p>

                                    <p> But, Marketplaces operate in Silos. They don’t share enough data with to act on and we are here to change that!!</p>

                                    <p> With seamless integrations with various marketplaces, we make your data tracking easy and help you with the required stats & suggestions to scale on marketplaces.
                                    </p>
                                    {/* <p>From the total order count to the revenue generated. We've got you covered!</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.levelUpContainer}>
                        <h3><span className={styles.highlight}>Ready to level up</span> <br />your business?</h3>
                        <p>Omnichannel Retail | Marketing | Logistics | Distribution</p>


                    </div>
                </div>
                <div ref={blackRef} style={{ maxWidth: '100%' }}>
                    <div className={styles.usespaces} style={{ maxWidth: '100%' }}>
                        <h1>Start today and unlock limitless growth possibilities.</h1>
                        <Link to='/contact-us' style={{ textDecoration: 'none', color: 'black' }} className={styles.getStartedBtn}>Get Started</Link>
                        {/* <img alt='arrow-icon' src='/assets/images/white-arrow.png' /> */}
                        {/* <img alt='hyperspace' src='/assets/images/hyperspacelogo.png' /> */}
                    </div>

                    {/* <div className={styles.questionsPerk} style={{ maxWidth: '100%' }}>
                        <img alt='questions' src='/assets/images/questions.png' />
                        <img alt='perks' src='/assets/images/perks.png' />
                    </div> */}
                </div>
                {/* <div className={styles.} ref={blackRef}>
                    <div>
                        <h1>Build the brand your product deserves.</h1>
                        <img alt='arrow-icon' src='/assets/images/white-arrow.png' />
                    </div>
                    <img alt='hyperspace' src='/assets/images/hyperspacelogo.png' />
                </div> */}






            </div>
            <Footer />


        </div>
    )
}

export default Sass

