import React, { useEffect, useState } from 'react'
import styles from './Tracking.module.css'
import Footer from '../../Components/Footer/Footer'

import { useParams } from 'react-router-dom'

import axios from 'axios'

// import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
    'PLACED',
    'SHIPPED',
    'IN-TRANSIT',
    'OUT FOR DELIVERY',
    'DELIVERED',
];








function Tracking() {
    const { id } = useParams();


    const [trackingDetials, setTrackingDetials] = useState<any>("")

    useEffect(() => {

        const Url = `https://api-np.gohyper.space/api/v1/order/track?client_order_id=${id}`
        const config: any = {
            method: 'get',
            url: Url,
            headers: {
                // Authorization: token,
                "Content-Type": "application/json",
            },
        }

        axios(config)
            .then((res) => {
                console.log(res?.data?.data)
                setTrackingDetials(res?.data?.data)

            })
            .catch((err) => {
                console.log(err)
            })

    }, [id])

    return (
        <div className={styles.maincontainer}>
            <div className={styles.container}>
                <img src='../assets/images/hyperspace.png' alt='logo' className={styles.logo} />
                <p style={{ textAlign: 'center' }}>Dear {trackingDetials.CustomerName}</p>
                <h3 style={{ textAlign: 'center' }}>Your Order is {trackingDetials.order_status}</h3>
                <h4 style={{ textAlign: 'center' }}>Your order with ID {trackingDetials.order_id} from {trackingDetials.company_name} has been delivered.</h4>
                <div className={styles.trackingContainer}>
                    <div className={styles.trackingStatus}>
                        <div className={styles.trackingDetails}>
                            <h2>TRACKING ID</h2>
                            <p>{trackingDetials.order_id}</p>
                        </div>
                        <div className={styles.trackingDetails}>
                            <h2>AWB</h2>
                            <p>{trackingDetials.awb_number}</p>
                        </div>
                        <div className={styles.trackingDetails}>
                            <h2>COURIER</h2>
                            <p>{trackingDetials.courier}</p>
                        </div>
                        <div className={styles.trackingDetails}>
                            <h2>PAYMENT</h2>
                            <p>{trackingDetials.payment_mode}</p>
                        </div>


                    </div>
                    <div className={styles.stepIndicator}>
                        <Stepper activeStep={trackingDetials.track_status} alternativeLabel >
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel StepIconProps={{ style: { color: index <= trackingDetials.track_status - 1 ? 'green' : 'grey' } }}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                    </div>

                </div>
                <div className={styles.itemsContainer}>

                    <div className={styles.itemheadingsContainer}>
                        <div className={styles.detailsContainer} style={{width:'50%'}}>
                            <h2>
                                ITEM SHIPPED
                            </h2>
                        </div>
                        <div className={styles.detailsContainer}>
                            <h2 >
                                QTY
                            </h2>
                        </div>
                        <div className={styles.detailsContainer}>
                            <h2>
                                AMOUNT
                            </h2>
                        </div>
                    </div>
                    {trackingDetials?.item?.map((eachItem: any) => {
                        return (
                            <div className={styles.itemsdetailsContainer} key={eachItem.quantity}>
                                <div className={styles.detailsContainer} style={{width:'50%'}}>
                                    <h3>{eachItem.name}</h3>
                                </div>
                                <div className={styles.detailsContainer}>
                                    <h3 style={{paddingLeft:'10%'}}>{eachItem.quantity}</h3>
                                </div>
                                <div className={styles.detailsContainer}>
                                    <h3 style={{paddingLeft:'10%'}}>{eachItem.amount}</h3>
                                </div>



                            </div>
                        )
                    })}

                    <hr />
                    <div className={styles.totalamountContainer}>
                        <h3>TOTAL</h3>
                        <h3>{trackingDetials.total_amount}</h3>

                    </div>
                    <hr />
                </div>

                <div className={styles.shippingDetailsContainer}>
                    <h3>SHIPPING DETAILS</h3>
                    <div className={styles.deliveryContainer}>
                        <div className={styles.delivery}>
                            <h3>DELIVERY ADDRESS</h3>
                            <p>{trackingDetials.CustomerName}</p>
                            <p>{trackingDetials?.delivery_address?.city}, {trackingDetials?.delivery_address?.state}, {trackingDetials?.delivery_address?.address_line_1}</p>
                            <p>{trackingDetials?.delivery_address?.pin_code}</p>
                        </div>
                        <div className={styles.seller}>
                            <h3>SELLER DETAILS</h3>
                            <p>{trackingDetials.company_name}</p>
                        </div>

                    </div>
                </div>

                <div className={styles.noteContainer}>
                    <h3>
                        NOTE:
                    </h3>
                    <p>
                        If the outer packaging has been tampered with, ripped, pressed, or is otherwise damaged, you can refuse acceptance of the delivery and write your comments on the proof of delivery (POD) slip that the delivery executive presents. If you receive an incorrect or damaged product after delivery, please take pictures or record a video of the disputed item and share it with the seller as soon as possible.
                    </p>

                </div>

                <h3>Best Regards!</h3>
                <h3>Team hyperspace.</h3>

            </div>
            <Footer />

        </div>
    )
}

export default Tracking
